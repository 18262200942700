import { Grid, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/images/loginlogo.png";
import { Box } from "@mui/system";
import { useStyles } from "../../styles";
import { verification1, verification2 } from "../../imageSource";
const Index = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      sx={{
        pt: "30px",
        height: { md: "103vh", xs: "105vh" },
        pb: { md: "0px", xs: "160px" },
        overflow: { md: "none", xs: "scroll !important" },
        background: { md: "#1B1B1B", xs: "#1B1B1B" },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        sx={{
          color: "white",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            mb: "99px",
            justifyContent: "center",
          }}
        >
          <Box display={{ md: "flex", xs: "none" }} mr="11.98px">
            <img src={logo} alt="" />
          </Box>
          <Box display={{ md: "none", xs: "flex" }} mr="11.98px">
            <img src={logo} alt="" width="42.5px" height="39.52px" />
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: { md: "29.273px", xs: "19.31px" },
              lineHeight: "53px",
            }}
          >
            Flash Affiliate
          </Typography>
        </Grid>
        <Box
          mb="96px"
          display={{ md: "none", xs: "flex", position: "relative" }}
        >
          <img
            src={verification2}
            alt=""
            style={{
              width: "67.2px",
              height: "67.2px",
              margin: "40px 0px 0px 50px",
              position: "absolute",
            }}
          />
          <img
            src={verification1}
            alt=""
            style={{
              width: "165.02px",
              height: "146.71px",
              position: "relative",
            }}
          />
        </Box>
        <Grid
          item
          md={12}
          xs={12}
          mb={{ md: "51px", xs: "0px" }}
          align="center"
        >
          <Typography className={classes.verification}>
            Your account is in verification. <br />
            The team will contact you if necessary.
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            mt: { md: "28.72px", xs: "8px" },
            // width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box display={{ md: "flex", xs: "none", position: "relative" }}>
            <img
              src={verification2}
              alt=""
              style={{ margin: "150px 0px 0px 170px", position: "absolute" }}
            />
            <img src={verification1} alt="" style={{ position: "relative" }} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Index;
