import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { AppInput, Button, Modal3, Modal7 } from "..";
import { bank, close, paypal, userimg } from "../../imageSource";
import { useStyles } from "../../styles";
import { useWithdrow } from "../../context/withdrow_context";

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {bankName,holder,iban,bic,setBankName,setHolder,setIBan,setBIC,amount,setAmount} = useWithdrow();
  const classes = useStyles();
  return (
    <div>
      <Box className={classes.modalcard} onClick={handleOpen}>
        <Box>
          <img src={bank} alt="" />
        </Box>
        <Box p={{ md: "0px", xs: "5px" }} width={{ md: "128px", xs: "93px" }}>
          <Typography
            mt={{ md: "16px", xs: "2px" }}
            className={classes.modal1text}
          >
            Bank Account
          </Typography>
          <Typography color="#BCBBB9" mt="4px" className={classes.modal2text}>
            Wire money directly to your bank account
          </Typography>
        </Box>
      </Box>

      <Box display={{ md: "none", xs: "flex" }}>
        <Button
          width="303px"
          textTransform="capitalize"
          label="Continue"
          border="none"
          height="41px"
          bg="#FBBF04"
          color={"#151103"}
          onClick={handleOpen}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        hideBackdrop={true}
        // BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            p: { md: "25px", xs: "25px 18px" },
            transform: "translate(-50%, -50%)",
            background: "#1B1B1B",
            borderRadius: "8px",
            flexDirection: "column",
            display: "flex",
            width: { md: "492px", xs: "335px" },
            height: "560px",
          }}
        >
          <Box
            pb="10px"
            borderBottom="1px solid #252525"
            display="flex"
            justifyContent="space-between"
          >
            <Typography className={classes.transactiontext}>
              Withdraw Money
            </Typography>
            <img src={close} alt="close" onClick={handleClose} />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt="35px"
            p="10px"
            borderRadius="8px"
            border="1px solid #252525"
          >
            <Box display="flex">
              <Box mr="12px">
                <img src={userimg} alt="" />
              </Box>
              <Box>
                <Typography className={classes.modal1text}>
                  John Smith
                </Typography>
                <Typography color="#717171" className={classes.Withdrowlist}>
                  johnsmith@gmail.com
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography mt="16px" className={classes.inputlable}>
            Enter your bank details
          </Typography>
          <Typography color="#717171" className={classes.Withdrowlist}>
            You can choose to save the following information for faster
            withdrawal next time.
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Box mt="13px">
              <Typography className={classes.inputlable}>Bank Name</Typography>
              <Box width={{ md: "213px", xs: "145px" }}>
                <AppInput
                  value={bankName}
                  onChange={(e)=>setBankName(e.target.value)}
                  type="City bank"
                  margin={"12.62px 0px 0px 0px"}
                  borderRadius={"10px"}
                  height="50px"
                  color="#F86E51"
                  placeholder="City bank"
                  backgroundColor="#161616"
                  position="end"
                />
              </Box>
            </Box>

            <Box mt="13px">
              <Typography className={classes.inputlable}>
                Account Holder
              </Typography>
              <Box width={{ md: "213px", xs: "145px" }}>
                <AppInput
                  value={holder}
                  onChange={(e)=>setHolder(e.target.value)}
                  type="John Smith"
                  margin={"12.62px 0px 0px 0px"}
                  // width="213px"
                  borderRadius={"10px"}
                  height="50px"
                  color="white"
                  placeholder="John Smith"
                  backgroundColor="#161616"
                  position="end"
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box mt="13px">
              <Typography className={classes.inputlable}>IBAN</Typography>
              <Box width={{ md: "213px", xs: "145px" }}>
                <AppInput
                  value={iban}
                  onChange={(e)=>setIBan(e.target.value)}
                  type=""
                  margin={"12.62px 0px 0px 0px"}
                  // width="213px"
                  borderRadius={"10px"}
                  height="50px"
                  color="white"
                  placeholder="56467*********"
                  backgroundColor="#161616"
                  position="end"
                />
              </Box>
            </Box>

            <Box mt="13px">
              <Typography className={classes.inputlable}>BIC</Typography>
              <Box width={{ md: "213px", xs: "145px" }}>
                <AppInput
                  value={bic}
                  onChange={(e)=>setBIC(e.target.value)}
                  type=""
                  margin={"12.62px 0px 0px 0px"}
                  // width="213px"
                  borderRadius={"10px"}
                  height="50px"
                  color="white"
                  placeholder="5476*****766"
                  backgroundColor="#161616"
                  position="end"
                />
              </Box>
            </Box>
          </Box>

          <Box width={{ md: "442px", xs: "303px" }} mt="24px">
            <Button
              textTransform="capitalize"
              label="Add Account"
              border="none"
              height="41px"
              bg="#FBBF04"
              color={"#151103"}
              onClick={handleOpen}
            />
          </Box>
          <Box mt="12px">
            <Modal3 />
            {/* <Box width={{ md: "442px", xs: "303px" }}>
              <Button
                textTransform="capitalize"
                label="Continue"
                height="41px"
                border="1px solid #FBBF04"
                color={"#FBBF04"}
              />
            </Box> */}
            {/* <Button
              width="442px"
              textTransform="capitalize"
              label="Continue"
              height="41px"
              border="1px solid #FBBF04"
              color={"#FBBF04"}
              onClick={handleOpen}
            /> */}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
