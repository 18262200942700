import React from "react";
import { Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
const CustomButton = ({
  width,
  label,
  bg,
  color,
  fontSize,
  border,
  height,
  textTransform,
  startIcon,
  endIcon,
  onClick,
  borderRadius,
  isLoading=false
}) => {
  return (
    <Button
      disabled={isLoading?true:false}
      fullWidth
      sx={{
      
        textTransform: textTransform,
        background: bg
          ? bg
          : "linear-gradient(180deg, rgba(161, 241, 55, 0.005) 0 %, rgba(161, 241, 55, 0.05) 100 %)",
        border: border ? border : "1px solid #FBBF04",
        fontSize: { fontSize },
        width: width,
        backdropFilter: "blur(100px)",
        borderRadius: borderRadius || "10px",
        color: color ? color : "white",
        fontWeight: "500",
        
        paddingY: "15px",
        ":hover": {
          opacity: 0.9,
          background: bg
            ? bg
            : "linear-gradient(180deg, rgba(161, 241, 55, 0.005) 0 %, rgba(161, 241, 55, 0.05) 100 %)",
        },
        height: height ? height : "54px",
      }}
    
    startIcon={startIcon}
    
      
      endIcon={endIcon}
      onClick={onClick}
    >
     {isLoading? (<CircularProgress/>):label}
      
     
    </Button>
  );
};

export default CustomButton;
