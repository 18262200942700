import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  Login,
  Overview,
  People,
  Commissions,
  Setting,
  Wallet,
  Leaderboard,
  Help,
  Statistics,
  SignUp,
  PendingAccount,
  ForgotPassword,
} from "../pages";
import WithdrowProvider from "../context/withdrow_context"
const AppRouter = () => {
  return (
    <>
     
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Signup" element={<SignUp />} />

          <Route path="/Overview" element={<Overview />} />
          <Route path="/Statistics" element={<Statistics />} />
          <Route path="/People" element={<People />} />
          <Route path="/Leaderboard" element={<Leaderboard />} />
          <Route path="/Commissions" element={<Commissions />} />
          <Route path="/Wallet" element={<WithdrowProvider><Wallet /></WithdrowProvider>} />

          <Route path="/Setting" element={<Setting />} />
          <Route path="/Help" element={<Help />} />
          <Route path="/PendingAccount" element={<PendingAccount />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />

        </Routes>
     
    </>
  );
};

export default AppRouter;
