import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { AppInput, Button, Modal3, Modal4 } from "..";
import { bank, close, crypto, paypal, userimg } from "../../imageSource";
import { useStyles } from "../../styles";
import { Link } from "react-router-dom";
import { useWithdrow } from "../../context/withdrow_context";

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  const {paypalEmail,setPaypalemail,amount,setAmount} = useWithdrow();
  return (
    <div>
      <Box className={classes.modalcard} onClick={handleOpen}>
        <Box>
          <img src={paypal} alt="" />
        </Box>
        <Box p={{ md: "0px", xs: "5px" }} width={{ md: "128px", xs: "93px" }}>
          <Typography
            mt={{ md: "16px", xs: "2px" }}
            className={classes.modal1text}
          >
            PayPal
          </Typography>
          <Typography color="#BCBBB9" mt="4px" className={classes.modal2text}>
            Transfer money directly to your PayPal
          </Typography>
        </Box>
      </Box>
      {/* <Button
        textTransform="capitalize"
        fontSize={"12px"}
        label="remove"
        height="41px"
        border="none"
        bg="#1B1B1B"
        color="#F86E51"
        onClick={handleOpen}
      /> */}
      <Modal
        open={open}
        onClose={handleClose}
        hideBackdrop={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            p: "25px",
            transform: "translate(-50%, -50%)",
            background: "#1B1B1B",
            borderRadius: "8px",
            flexDirection: "column",
            display: "flex",
            // width: "492px",
            width: { md: "492px", xs: "335px" },
            height: "351px",
          }}
        >
          <Box
            pb="10px"
            borderBottom="1px solid #252525"
            display="flex"
            justifyContent="space-between"
          >
            <Typography className={classes.lw} variant="h6" component="h2">
              Withdraw Money
            </Typography>
            <img src={close} alt="close" onClick={handleClose} />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="12px"
          ></Box>

          <Box display="flex" justifyContent="space-between">
            <Box mt="13px">
              <Typography className={classes.titless}>Paypal Email</Typography>
              <Box width={{ md: "443px", xs: "301.64px" }}>
                <AppInput
                  value={paypalEmail}
                  onChange ={(e)=>setPaypalemail(e.target.value)}
                  type="City bank"
                  margin={"12.62px 0px 0px 0px"}
                  // width="443px"
                  borderRadius={"10px"}
                  height="50px"
                  color="#F86E51"
                  placeholder="Enter your paypal.."
                  backgroundColor="#161616"
                  position="end"
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box mt="13px">
              <Typography className={classes.titless}>Amount</Typography>
              <Box width={{ md: "443px", xs: "301.64px" }}>
                <AppInput
                  type=""
                  value={amount}
                  onChange={(e)=>setAmount(e.target.value)}
                  margin={"12.62px 0px 0px 0px"}
                  // width="443px"
                  borderRadius={"10px"}
                  height="50px"
                  color="white"
                  placeholder="500"
                  backgroundColor="#161616"
                  position="end"
                />
              </Box>
            </Box>
          </Box>

          <Box width={{ md: "442px", xs: "300.96px" }} mt="24px">
            {/* <Button
              // width="442px"
              textTransform="capitalize"
              label="Continue"
              height="41px"
              bg="#FBBF04"
              border="none"
              color={"#151103"}
              onClick={handleOpen}
            /> */}
            <Modal4 name />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
