import { Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { AppInput, Button } from "../../components";
import password from "../../assets/images/password.png";

import { Box } from "@mui/system";
import { useStyles } from "../../styles";
import { Link } from "react-router-dom";
import { settingp } from "../../imageSource";
import { useAuth } from "../../context/auth_context";
import { toast } from "react-toastify";
import { getDownloadUrl, useImgUpload } from "../../hooks/firebase";
import { storage } from "../../firebase";
import { useUpdateProfile, useUpdateProfileImg } from "../../hooks/useProfile";
import { useQueryClient } from "@tanstack/react-query";
import CircularProgress from "@mui/material/CircularProgress";
const Index = () => {
  const classes = useStyles();
  const { user, userIsFetching } = useAuth();

  const [firstName, setFirstName] = useState(
    user?.firstName ? user.firstName : ""
  );

  const [lastName, setLastName] = useState(user?.lastName ? user.lastName : "");

  const [userName, setUserName] = useState(user?.userName ? user.userName : "");
  const [phone, setPhone] = useState(user?.phone ? user.phone : "");
  const [email, setEmail] = useState(user?.email ? user.email : "");
  const [confirmEmail, setComfirmEmail] = useState(
    user?.email ? user.email : ""
  );
  const inputRef = useRef();
  const fileUploader = useImgUpload();
  const updateProfileHandler = useUpdateProfile();
  const { isLoading: updateIsLoading } = updateProfileHandler;
  const profileImgHandler = useUpdateProfileImg();
  const { isLoading, data, isSuccess } = fileUploader;
  const {
    isLoading: imgIsLoading,
    data: ImgData,
    isSuccess: imgIsSuccess,
  } = profileImgHandler;
  const queryClient = useQueryClient();
  useEffect(() => {
    setFirstName(user?.firstName ? user.firstName : "");
    setLastName(user?.lastName ? user.lastName : "");
    setUserName(user?.userName ? user.userName : "");
    setPhone(user?.phone ? user.phone : "");
    setEmail(user?.email ? user.email : "");
    setComfirmEmail(user?.email ? user.email : "");
  }, [user]);
  useEffect(() => {
    if (isSuccess && data) {
      getDownloadUrl(data.metadata.fullPath).then((url) => {
        profileImgHandler.mutate(url);
      });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (imgIsSuccess && ImgData?.data?.status) {
      queryClient.invalidateQueries(["getUser"]);
    }
  }, [imgIsSuccess, ImgData]);

  const handleSave = () => {
    updateProfileHandler.mutate({
      firstName,
      lastName,
      userName,
      email,
      confirmEmail,
      phone,
    });
  };
  const handleReset = () => {
    setFirstName(user?.firstName ? user.firstName : "");
    setLastName(user?.lastName ? user.lastName : "");
    setUserName(user?.userName ? user.userName : "");
    setPhone(user?.phone ? user.phone : "");
    setEmail(user?.email ? user.email : "");
    setComfirmEmail(user?.email ? user.email : "");
  };
  const handleUploadImg = (e) => {
    const curFile = e.target.files[0];
    if (!curFile) return toast.warning("Please select file!");
    const tmp = curFile.name.split(".");
    const ext = tmp[tmp.length - 1];
    if (ext === "jpg" || ext === "jpeg" || ext === "png") {
      fileUploader.mutate(curFile);
    } else {
      return toast.warning("You can select JPG,JPEG,PNG files");
    }
  };
  const handleImgChange = () => {
    const { current } = inputRef;
    (current || { click: () => {} }).click();
  };
  return (
    <Grid
      container
      sx={{
        mt: { md: "59px", xs: "16px" },
        background: "#1B1B1B",
        padding: "20px 30px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <Grid item md={12} xs={12}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "17px",
            lineHeight: "53px",
          }}
        >
          Account
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: "500",
            fontSize: "12px",
            color: "#BCBBB9",
            lineHeight: "150%",
          }}
        >
          Your username will be displayed publicly so be careful what you share.
        </Typography>
      </Grid>
      {!userIsFetching ? (
        <>
          <Grid
            item
            md={12}
            xs={12}
            mb="26px"
            mt="16px"
            pt="24px"
            style={{
              borderTop: "1px solid #252525",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box mr="16px">
              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleUploadImg}
                ref={inputRef}
              />
              <img
                src={user?.profileImg ? user?.profileImg : settingp}
                width={80}
                height={80}
                alt=""
                style={{ borderRadius: "100px" }}
              />
            </Box>
            <Button
              onClick={handleImgChange}
              width="83px"
              textTransform="capitalize"
              isLoading={isLoading || imgIsLoading}
              label="Change"
              border="none"
              height="41px"
              bg="#252525"
              color={"white"}
            />
          </Grid>
          <Grid item md={6} mb="16px">
            <Typography className={classes.accordtext}>First Name</Typography>
            <Box width={{ md: "395px", xs: "303px" }}>
              <AppInput
                margin={"12.62px 0px 0px 0px"}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                // width="395px"
                borderRadius={"10px"}
                height="57.44px"
                // colorP="717171"
                color="white"
                placeholder="Enter your name"
                backgroundColor="#161616"
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Typography className={classes.accordtext}>Last Name</Typography>
            <Box width={{ md: "395px", xs: "303px" }}>
              <AppInput
                margin={"12.62px 0px 0px 0px"}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                // width="395px"
                borderRadius={"10px"}
                height="57.44px"
                // colorP="717171"
                color="white"
                placeholder="Enter your last name"
                backgroundColor="#161616"
              />
            </Box>
          </Grid>{" "}
          <Grid item md={6}>
            <Typography className={classes.accordtext}>Username</Typography>
            <Box width={{ md: "395px", xs: "303px" }}>
              <AppInput
                margin={"12.62px 0px 0px 0px"}
                // width="395px"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                borderRadius={"10px"}
                height="57.44px"
                // colorP="717171"
                color="white"
                placeholder="Enter your username"
                backgroundColor="#161616"
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Typography className={classes.accordtext}>Phone Number</Typography>
            <Box width={{ md: "395px", xs: "303px" }}>
              <AppInput
                margin={"12.62px 0px 0px 0px"}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                // width="395px"
                borderRadius={"10px"}
                height="57.44px"
                // colorP="717171"
                color="white"
                placeholder="+33"
                backgroundColor="#161616"
              />
            </Box>
          </Grid>
          <Grid item md={6} mt="22px">
            <Typography className={classes.titless}>Email</Typography>
            <Box width={{ md: "395px", xs: "303px" }}>
              <AppInput
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="Enter your mail"
                margin={"12.62px 0px 0px 0px"}
                // width="395px"
                borderRadius={"10px"}
                height="57.44px"
                color="white"
                placeholder="Password"
                backgroundColor="#161616"
                position="end"
                inputIcon={<img src={password} alt="" />}
              />
            </Box>
          </Grid>
          <Grid item md={6} mt="22px">
            <Typography className={classes.titless}>Confirm Email</Typography>
            <Box width={{ md: "395px", xs: "303px" }}>
              <AppInput
                value={confirmEmail}
                onChange={(e) => setComfirmEmail(e.target.value)}
                type="password"
                margin={"12.62px 0px 0px 0px"}
                // width="395px"
                borderRadius={"10px"}
                height="57.44px"
                color="white"
                placeholder="Confirm your mail"
                backgroundColor="#161616"
                position="end"
                inputIcon={<img src={password} alt="" />}
              />
            </Box>
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            paddingTop: "50px",
          }}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      <Grid
        item
        md={10.2}
        xs={12}
        sx={{
          pt: { md: "82px", xs: "24px" },
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Link
          to="/Overview"
          style={{ textDecoration: "none", marginRight: "20px" }}
        >
          <Button
            onClick={handleReset}
            width="93px"
            textTransform="capitalize"
            label="Reset"
            border="1px solid #FBBF04"
            height="57.44px"
            color={"#FBBF04"}
          />
        </Link>
        <Button
          width="144px"
          textTransform="capitalize"
          isLoading={updateIsLoading}
          onClick={handleSave}
          label="Save Changes"
          border="none"
          height="57.44px"
          bg="#FBBF04"
          color={"black"}
        />
      </Grid>
    </Grid>
  );
};

export default Index;
