import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { NavLink } from "react-router-dom";
import styles from "../leftbar/style.module.css";

import imgLogo from "../../assets/images/logo.png";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import { BiMenu } from "react-icons/bi";
import { useStyles } from "../../styles";

import {
  CommissionB,
  CommissionW,
  helpB,
  helpW,
  leaderB,
  leaderW,
  overviewB,
  overviewW,
  peopleB,
  peopleW,
  settingB,
  settingW,
  staticB,
  staticW,
  walletB,
  walletW,
} from "../../imageSource";
import { Typography } from "@mui/material";
import { Button } from "..";
// const drawerWidth = 240;

// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginRight: -drawerWidth,
//     ...(open && {
//       transition: theme.transitions.create("margin", {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginRight: 0,
//     }),
//   })
// );

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   transition: theme.transitions.create(["margin", "width"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginRight: drawerWidth,
//   }),
// }));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function MobileDrawer({ mobile }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(true);
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <IconButton
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        sx={{ ...(open && { display: "none" }), color: "rgb(176, 41, 34)" }}
      >
        <BiMenu color="white" fontSize={30} />
        {/* <MenuIcon fontSize="large"  /> */}
      </IconButton>

      <DrawerHeader />

      <Drawer
        sx={{
          color: "white !important",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "100%",
            backgroundColor: "#1B1B1B",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {/* <br className="mo-show-only" /> */}
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          {" "}
          <Button
            width="10px"
            border="none"
            onClick={handleDrawerClose}
            startIcon={<CloseIcon />}
          />
        </Box>
        <Box mt="22px">
          <Box className={classes.TitleDiv}>
            <Box>
              <img src={imgLogo} alt="Flash Technology" />
            </Box>
            <Typography className={classes.lTitle} ml="10px">
              Flash Affiliate
            </Typography>
          </Box>

          <Box className={styles.linkContainer}>
            <Box onClick={() => setValue(!value)}>
              <NavLink
                to="/Overview"
                className={({ isActive }) =>
                  isActive ? styles.activeLink : styles.inActiveLink
                }
              >
                {value ? (
                  <img
                    src={overviewW}
                    alt="Staking"
                    className={styles.imgBar}
                  />
                ) : (
                  /* // <BsFillGrid1X2Fill className={styles.imgBar} /> */
                  <img
                    src={overviewB}
                    alt="Staking"
                    className={styles.imgBar}
                  />
                  /* // <MdInsertChart className={styles.imgBar} /> */
                )}
                Overview
                {/* {mobile > 300 ? "Staking"  */}
              </NavLink>
            </Box>
            <Box onClick={() => setValue(!value)}>
              <NavLink
                to="/Statistics"
                className={({ isActive }) =>
                  isActive ? styles.activeLink : styles.inActiveLink
                }
              >
                {value ? (
                  <img src={staticW} alt="Staking" className={styles.imgBar} />
                ) : (
                  <img src={staticB} alt="Staking" className={styles.imgBar} />
                )}
                {/* <GoGraph className={styles.imgBar} /> */}
                {/* <img src={Stackdashboard} alt="Profile" className={styles.imgBar} /> */}
                Statistics
              </NavLink>
            </Box>
            <Box onClick={() => setValue(!value)}>
              <NavLink
                to="/people"
                className={({ isActive }) =>
                  isActive ? styles.activeLink : styles.inActiveLink
                }
              >
                {value ? (
                  <img src={peopleW} alt="Profile" className={styles.imgBar} />
                ) : (
                  // <FaUserFriends className={styles.imgBar} />
                  <img src={peopleB} alt="Staking" className={styles.imgBar} />
                )}
                People
              </NavLink>
            </Box>
            <NavLink
              to="/Leaderboard"
              className={({ isActive }) =>
                isActive ? styles.activeLink : styles.inActiveLink
              }
            >
              {value ? (
                <img src={leaderW} alt="Profile" className={styles.imgBar} />
              ) : (
                // <FaUserFriends className={styles.imgBar} />
                <img src={leaderB} alt="Staking" className={styles.imgBar} />
              )}
              {/* <MdLeaderboard className={styles.imgBar} /> */}
              {/* <img src={setting} alt="Profile" className={styles.imgBar} /> */}
              Leaderboard
            </NavLink>
            <NavLink
              to="/Commissions"
              className={({ isActive }) =>
                isActive ? styles.activeLink : styles.inActiveLink
              }
            >
              {" "}
              {value ? (
                <img
                  src={CommissionW}
                  alt="Profile"
                  className={styles.imgBar}
                />
              ) : (
                // <FaUserFriends className={styles.imgBar} />
                <img
                  src={CommissionB}
                  alt="Staking"
                  className={styles.imgBar}
                />
              )}
              {/* <TbMathSymbols className={styles.imgBar} /> */}
              {/* <img src={imgEnvelope} alt="Support" className={styles.imgBar} /> */}
              Commissions
            </NavLink>
            <NavLink
              to="/Wallet"
              className={({ isActive }) =>
                isActive ? styles.activeLink : styles.inActiveLink
              }
            >
              {value ? (
                <img src={walletW} alt="Profile" className={styles.imgBar} />
              ) : (
                // <FaUserFriends className={styles.imgBar} />
                <img src={walletB} alt="Staking" className={styles.imgBar} />
              )}
              {/* <FaWallet className={styles.imgBar} /> */}
              Wallet
            </NavLink>
            <NavLink
              to="/Setting"
              className={({ isActive }) =>
                isActive ? styles.activeLink : styles.inActiveLink
              }
            >
              {value ? (
                <img src={settingW} alt="Profile" className={styles.imgBar} />
              ) : (
                // <FaUserFriends className={styles.imgBar} />
                <img src={settingB} alt="Staking" className={styles.imgBar} />
              )}
              {/* <MdSettingsApplications className={styles.imgBar} /> */}
              Settings
            </NavLink>
            <NavLink
              to="/Help"
              className={({ isActive }) =>
                isActive ? styles.activeLink : styles.inActiveLink
              }
            >
              {value ? (
                <img src={helpW} alt="Profile" className={styles.imgBar} />
              ) : (
                // <FaUserFriends className={styles.imgBar} />
                <img src={helpB} alt="Staking" className={styles.imgBar} />
              )}
              {/* <IoHelpCircle className={styles.imgBar} /> */}
              Help
            </NavLink>
          </Box>
        </Box>
        {/* <Divider /> */}
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "20px",
            mt: "30px",
            ml: "25px",
          }}
        >
          <a href="https://flash-technologies.org/" target="_blink">
            <img className="" width={"25px"} src={WebsiteIcon} alt="website" />
          </a>
          <a href="https://twitter.com/Flash_Techno_Of" target="_blink">
            <FaTwitter color="white" fontSize={"29px"} />
          </a>
          <a href="https://t.me/flashtokenenglish" target="_blink">
            <FaTelegramPlane color="white" fontSize={"29px"} />
          </a>
        </Box> */}
      </Drawer>
    </Box>
  );
}
