import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import { useStyles } from "../../styles";

const Index = () => {
  const classes = useStyles();
  return (
    <Box sx={{ paddingRight: { md: "20px", xs: "0px" } }}>
      <Accordion style={{ backgroundColor: "#141414", margin: "15px 0px" }}>
        <AccordionSummary
          style={{
            backgroundColor: "#1B1B1B",
            padding: "15px 20px",
            borderRadius: "8px",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.accordtext}>
            How can i connect wallet?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ marginTop: "20px", backgroundColor: "#1B1B1B" }}
        >
          <Typography color="#BCBBB9">
            You can sign into your account on Flash Affiliate and see your
            messages, call history, blocked numbers – everything you can do from
            the app. So, imagine you’re at a friend’s place and your phone
            breaks or your battery dies. You can jump on the nearest computer
            and still be able to get your messages or make a call,
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ backgroundColor: "#141414", margin: "15px 0px" }}>
        <AccordionSummary
          style={{
            backgroundColor: "#1B1B1B",
            padding: "15px 20px",
            borderRadius: "8px",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.accordtext}>
            How can i connect wallet?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ marginTop: "20px", backgroundColor: "#1B1B1B" }}
        >
          <Typography color="#BCBBB9">
            You can sign into your account on Flash Affiliate and see your
            messages, call history, blocked numbers – everything you can do from
            the app. So, imagine you’re at a friend’s place and your phone
            breaks or your battery dies. You can jump on the nearest computer
            and still be able to get your messages or make a call,
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ backgroundColor: "#141414", margin: "15px 0px" }}>
        <AccordionSummary
          style={{
            backgroundColor: "#1B1B1B",
            padding: "15px 20px",
            borderRadius: "8px",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.accordtext}>
            How can i connect wallet?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ marginTop: "20px", backgroundColor: "#1B1B1B" }}
        >
          <Typography color="#BCBBB9">
            You can sign into your account on Flash Affiliate and see your
            messages, call history, blocked numbers – everything you can do from
            the app. So, imagine you’re at a friend’s place and your phone
            breaks or your battery dies. You can jump on the nearest computer
            and still be able to get your messages or make a call,
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ backgroundColor: "#141414", margin: "15px 0px" }}>
        <AccordionSummary
          style={{
            backgroundColor: "#1B1B1B",
            padding: "15px 20px",
            borderRadius: "8px",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.accordtext}>
            How can i connect wallet?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ marginTop: "20px", backgroundColor: "#1B1B1B" }}
        >
          <Typography color="#BCBBB9">
            You can sign into your account on Flash Affiliate and see your
            messages, call history, blocked numbers – everything you can do from
            the app. So, imagine you’re at a friend’s place and your phone
            breaks or your battery dies. You can jump on the nearest computer
            and still be able to get your messages or make a call,
          </Typography>
        </AccordionDetails>
      </Accordion>{" "}
      <Accordion style={{ backgroundColor: "#141414", margin: "15px 0px" }}>
        <AccordionSummary
          style={{
            backgroundColor: "#1B1B1B",
            padding: "15px 20px",
            borderRadius: "8px",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.accordtext}>
            How can i connect wallet?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ marginTop: "20px", backgroundColor: "#1B1B1B" }}
        >
          <Typography color="#BCBBB9">
            You can sign into your account on Flash Affiliate and see your
            messages, call history, blocked numbers – everything you can do from
            the app. So, imagine you’re at a friend’s place and your phone
            breaks or your battery dies. You can jump on the nearest computer
            and still be able to get your messages or make a call,
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Index;
