import { Checkbox, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { AppInput, Button, Header } from "..";
import logo from "../../assets/images/loginlogo.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box } from "@mui/system";
import { useStyles } from "../../styles";
import {toast} from "react-toastify";
import {  useNavigate } from "react-router-dom";
import { earn, email, get, share } from "../../imageSource";
import {useSignUpOnBack} from "../../hooks/useProfile";
const Index = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [firstName,setFirstName] = useState("");
  const [lastName,setLastName] = useState("");
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("password");
  const signUpHandler = useSignUpOnBack();

  const {isLoading,data,isSuccess} = signUpHandler;
  const handleSignUp = ()=>{
    if(firstName === "" || lastName ==="" ||email==="" ||password==="" ){
      return toast.warning("Please input correctly");
    }
    signUpHandler.mutate({firstName,lastName,email,password});
  }
  return (
    <Grid
      item
      md={12}
      sx={{
        pt: "30px",
        height: "105vh",
      
        pb: { md: "120px", xs: "160px" },
        overflow: { md: "none", xs: "scroll !important" },
        background: { md: "#1B1B1B", xs: "black" },
      }}
    >
      <Grid
        item
        xs={11}
        md={12}
        sx={{
          mt: "20px",
          display: { md: "flex", xs: "none" },
          alignItems: "center",
          mb: "109px",
          justifyContent: "center",
        }}
      >
        <Box display={{ md: "flex", xs: "none" }} mr="11.98px">
          <img src={logo} alt="" />
        </Box>
        <Box display={{ md: "none", xs: "flex" }} mr="11.98px">
          <img src={logo} alt="" width="42.5px" height="39.52px" />
        </Box>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: { md: "29.273px", xs: "19.31px" },
            lineHeight: "53px",
          }}
        >
          Flash Affiliate
        </Typography>
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { md: "row", xs: "column" },
        }}
      >
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            md={8}
            xs={11.5}
            sx={{
              display: "flex",
              flexDirection: "column",
              p: { md: "32px", xs: "10px" },
              borderRadius: "16px",
              border: "1px solid #D1D5DB",
            }}
          >
            <Grid
              sx={{
                display: { md: "none", xs: "flex" },
              }}
            >
              <Header />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography className={classes.referral}>
                Referral Program
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "27px",
                }}
              >
                Sign up for the referral program
              </Typography>
            </Grid>
            <Grid item md={12} mt="16px">
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                First Name
              </Typography>
              <Box width={{ md: "auto", xs: "335px" }}>
                <AppInput
                  margin={"12.62px 0px 0px 0px"}
                  value={firstName}
                  onChange={(e)=>setFirstName(e.target.value)}
                  // width="563.29px"
                  borderRadius={"6px"}
                  height="42px"
                  border="1px solid #D1D5DB"
                  color="white"
                  placeholder="Numan"
                  backgroundColor="#161616"
                />
              </Box>
            </Grid>
            <Grid item md={12} mt="24px">
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Last Name
              </Typography>
              <Box width={{ md: "auto", xs: "335px" }}>
                <AppInput
                value={lastName}
                onChange={(e)=>setLastName(e.target.value)}
                  margin={"12.62px 0px 0px 0px"}
                  // width="563.29px"
                  border="1px solid #D1D5DB"
                  borderRadius={"6px"}
                  height="42px"
                  color="white"
                  placeholder="Zafar"
                  backgroundColor="#161616"
                />
              </Box>
            </Grid>
            <Grid item md={12} mt="22px">
              <Typography className={classes.titless}>Email address</Typography>
              <Box width={{ md: "auto", xs: "335px" }}>
                <AppInput
                value={email}
                onChange={e=>setEmail(e.target.value)}
                  type="text"
                  margin={"12.62px 0px 0px 0px"}
                  // width="563.29px"
                  borderRadius={"6px"}
                  height="42px"
                  color="white"
                  border="1px solid #D1D5DB"
                  placeholder="you@example.com"
                  backgroundColor="#161616"
                  position="end"
                  inputIcon={<img src={email} alt="" />}
                />
              </Box>{" "}
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                mt: "28.72px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box display="flex" alignItems="center">
                <Checkbox
                  className={classes.checkbox}
                  size="medium"
                  // style={{
                  //   // borderRadius: "14px",
                  //   color: "black ",
                  // }}
                />

                <Grid
                  item
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#BCBBB9",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "19px",
                    }}
                  >
                    I agree with
                  </Typography>
                  <Typography
                    sx={{
                      paddingLeft: "10px",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#FCC005",
                    }}
                  >
                    Terms of use
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                mt: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                width="100%"
                textTransform="capitalize"
                label="Sign Up"
                border="none"
                fontSize="20px"
                height="44px"
                bg="#FBBF04"
                isLoading={isLoading}
                color={"white"}
                endIcon={<ArrowRightAltIcon />}
                onClick={handleSignUp}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
          sx={{
            mt: { md: "95px", xs: "83px" },
            display: "flex",
            flexDirection: "column",
            p: { md: "32px", xs: "10px" },
          }}
        >
          <Box display="flex">
            <Box mr="24px">
              <img src={get} alt="" />
            </Box>
            <Box>
              <Typography className={classes.getText}>Get your code</Typography>
              <Typography className={classes.transactiontext}>
                After the validation of your registration find your affiliate
                link and your code on the Flash Affiliates interface
              </Typography>
            </Box>
          </Box>
          <Box display="flex" mt="60px">
            <Box mr="24px">
              <img src={share} alt="" />
            </Box>
            <Box>
              <Typography className={classes.getText}>
                Share with colleagues
              </Typography>
              <Typography className={classes.transactiontext}>
                Share your affiliate link and receive commissions
              </Typography>
            </Box>
          </Box>
          <Box display="flex" mt="60px">
            <Box mr="24px">
              <img src={earn} alt="" />
            </Box>
            <Box>
              <Typography className={classes.getText}>Earn Rewards</Typography>
              <Typography className={classes.transactiontext}>
                Earn points every time your affiliates spend on Flash Transfer
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Index;
