import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button } from "..";
import { close, congrats, copyicon } from "../../imageSource";
import { useStyles } from "../../styles";
import { useWithdrow } from "../../context/withdrow_context";
import { useVerifySMSForWithdrow } from "../../hooks/withdrow";
import { toast } from "react-toastify";
export default function BasicModal() {
  const { payWay } = useWithdrow();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  const {trId} = useWithdrow();
  const verifyHandler = useVerifySMSForWithdrow();
  const [transaction,setTransaction] = React.useState(null);
  const {isLoading:isVerifyLoading,data:verifyData,isError:isVerifyError} = verifyHandler;
  

  const handleWithdrow =React.useCallback(()=>{
    console.log("trId:",trId);
      if(trId){
          verifyHandler.mutate({id:trId,smsCode:"665357"})
      }
  },[trId])
React.useEffect(()=>{
  if(verifyData?.data?.status){
    handleOpen();
    setTransaction(verifyData?.data?.transaction);
  }else{
    toast.error(verifyData?.data?.msg);
  }
},[verifyData])
  return (
    <div>
      <Box width={{ md: "442px", xs: "303px" }} ml={{ md: "0px", xs: "-8px" }}>
        <Button
          textTransform="capitalize"
          label="Withdraw"
          border="none"
          height="41px"
          bg="#FBBF04"
          color={"#151103"}
          onClick={handleWithdrow}
        />
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        hideBackdrop={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Box
            sx={{
              background: "#1B1B1B",
              borderRadius: "8px",
              p: { md: "25px", xs: "15px" },
              width: { md: "492px", xs: "343px" },
              height: "auto",
              transform: { md: "scale(1)", xs: "scale(0.97)" },
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography className={classes.transactiontext}>
                Withdraw Money
              </Typography>
              <img src={close} alt="close" onClick={handleClose} />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "column",
                mt: "32px",
              }}
            >
              <Box mr="12px">
                <img src={congrats} alt="" />
              </Box>
              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography className={classes.congrats}>
                  Congratulations
                </Typography>
                <Typography
                  color="#BCBBB9"
                  textAlign="center"
                  className={classes.Withdrowlist}
                >
                  Your withdrawal request is taken into account and your money
                  will be transferred to you account in 1-2 business days
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography mt="16px" className={classes.inputlable}>
                  20 June 2023 20:38 UTC +1
                </Typography>
                <Box display="flex" alignItems={"center"}>
                  <Typography color="#717171" className={classes.Withdrowlist}>
                    Trans ID {transaction?transaction.invoice:""}
                  </Typography>
                  <Box ml="5px">
                    <img src={copyicon} alt="" />
                  </Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="end" flexDirection="column">
                <Typography className={classes.inputlable}>${transaction?transaction.amount:0}</Typography>
                <Typography color="#F86E51" className={classes.Withdrowlist}>
                  {transaction?(transaction.status===0?"Pending":"Complete"):("")}
                </Typography>
              </Box>
            </Box>
            <Box mt={{ md: "26px", xs: "10px" }}>
              <Typography className={classes.inputlable}>
                Account Details
              </Typography>
            </Box>
            {payWay === 0 ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    mt="16px"
                    color="#BCBBB9"
                    className={classes.Withdrowlist}
                  >
                    TX
                  </Typography>
                  <Typography
                    mt="10px"
                    color="#BCBBB9"
                    className={classes.Withdrowlist}
                  >
                    Crypto Address
                  </Typography>
                  <Typography
                    mt="10px"
                    color="#BCBBB9"
                    className={classes.Withdrowlist}
                  >
                    Network
                  </Typography>
                  <Typography
                    mt="10px"
                    color="#BCBBB9"
                    className={classes.Withdrowlist}
                  >
                    crypto
                  </Typography>{" "}
                </Box>
                <Box
                  mb="10px"
                  display="flex"
                  alignItems="end"
                  flexDirection="column"
                >
                  <Box display="flex" mt="10px" alignItems="center">
                    <Typography
                      mt="16px"
                      color="#FBBF04"
                      className={classes.Withdrowlist}
                    >
                      https://bscscan.com/tx/0x3f8339d23e
                    </Typography>
                    <Box ml="2px" mt="10px">
                      <img src={copyicon} alt="" />
                    </Box>
                  </Box>
                  <Typography mt="10px" className={classes.Withdrowlist}>
                    0x.............................
                  </Typography>
                  <Box display="flex" mt="10px" alignItems="center">
                    <Typography mt="5px" className={classes.Withdrowlist}>
                      BNB Chain
                    </Typography>
                  </Box>
                  <Typography mt="10px" className={classes.Withdrowlist}>
                    BUSD
                  </Typography>
                </Box>
              </Box>
            ) : payWay == 2 ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    mt="16px"
                    color="#BCBBB9"
                    className={classes.Withdrowlist}
                  >
                    Email
                  </Typography>
                  <Typography
                    mt="10px"
                    color="#BCBBB9"
                    className={classes.Withdrowlist}
                  >
                    Account Holder
                  </Typography>
                </Box>
                <Box
                  mb="10px"
                  display="flex"
                  alignItems="end"
                  flexDirection="column"
                >
                  <Typography mt="10px" className={classes.Withdrowlist}>
                    test@paypal.com
                  </Typography>
                  <Typography mt="10px" className={classes.Withdrowlist}>
                    Peter Brandstetter
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    mt="16px"
                    color="#BCBBB9"
                    className={classes.Withdrowlist}
                  >
                    Bank
                  </Typography>
                  <Typography
                    mt="10px"
                    color="#BCBBB9"
                    className={classes.Withdrowlist}
                  >
                    Account Holder
                  </Typography>
                  <Typography
                    mt="10px"
                    color="#BCBBB9"
                    className={classes.Withdrowlist}
                  >
                    IBAN
                  </Typography>
                  <Typography
                    mt="10px"
                    color="#BCBBB9"
                    className={classes.Withdrowlist}
                  >
                    BIC
                  </Typography>{" "}
                </Box>
                <Box
                  mb="10px"
                  display="flex"
                  alignItems="end"
                  flexDirection="column"
                >
                  <Box display="flex" mt="10px" alignItems="center">
                    <Typography mt="5px" className={classes.Withdrowlist}>
                      {transaction?transaction.bankName:""}
                    </Typography>
                  </Box>
                  <Typography mt="16px" className={classes.Withdrowlist}>
                    {transaction?transaction.bankHolder:""}
                  </Typography>
                  <Box display="flex" mt="10px" alignItems="center">
                    <Typography
                      mt="10px"
                      color="#315CF5"
                      className={classes.Withdrowlist}
                    >
                      Trans ID {transaction?transaction.bankIban:""}{" "}
                    </Typography>
                    <Box ml="2px" mt="5px">
                      <img src={copyicon} alt="" />
                    </Box>
                  </Box>

                  <Typography mt="10px" className={classes.Withdrowlist}>
                    {transaction?transaction.bankBic:""}
                  </Typography>
                </Box>
              </Box>
            )}
            <Box
              borderTop="1px solid #252525"
              sx={{
                background: "#252525",
                mt: { md: "36px", xs: "0px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: { md: "20px", xs: "10px" },
                borderRadius: "8px",
              }}
            >
              <Box width="244px">
                <Typography mt="16px" className={classes.congrats}>
                  Need help
                </Typography>
                <Typography color="#BCBBB9" className={classes.Withdrowlist}>
                  If there is a problem with the transactions make sure to
                  contact your support.
                </Typography>
              </Box>
              <Box mt="15px">
                <Button
                  width="97px"
                  textTransform="capitalize"
                  label="See detail"
                  height="41px"
                  color={"#FBBF04"}
                  onClick={handleOpen}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
