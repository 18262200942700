import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "../../styles";
import CircularProgress from '@mui/material/CircularProgress';

const Index = ({ label, value, img ,isFetching }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} lg={3}>
      <Box className={classes.amountcard}>
        <Box>
          <img src={img} />
        </Box>
        {
          !isFetching?(
            <Box ml="24px">
              <Typography color="white" className={classes.amount1}>
                {label}
              </Typography>
              <Typography color="white" className={classes.aDuration}>
                {value}
              </Typography>
            </Box>
          ):(
            <Box ml="24px">
              <CircularProgress />
            </Box>
          )
        }
      </Box>
    </Grid>
  );
};

export default Index;
