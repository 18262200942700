import { Grid, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import {
  Layout,
  StatisticsCard,
  TopAffiliate,
  StatisticChart,
} from "../../components";
import people from "../../assets/images/peopleimg.svg";
import { useStyles } from "../../styles";
import { t1, t2, t3 } from "../../imageSource";
import { Box } from "@mui/system";
import { useRevenue, useTopAffiliators, useTotalPoint } from "../../hooks/statistics";
import { toast } from "react-toastify";
import { useAffiliatedUsers } from "../../hooks/leaderborad";
import CircularProgress from '@mui/material/CircularProgress';
const Index = () => {
  const classes = useStyles();
  const [method,setMethod] = useState("month") 
  const {isFetching,data,isError,Error} = useTotalPoint();
  
  const {isFetching:afIsFethcing,data:afData,isError:afIsError,Error:afError} = useAffiliatedUsers();
  const {isFetching:isRevFetching,data:revData,isError:isRevError,Error:revError} = useRevenue(method);
  const RevData  = useMemo(()=>{
    if(!isError && revData?.status){
        return revData?.data;
    }else{
        return []
    }
  },[isError,revData,method])
  if(isError )toast.error(Error?.message)

  if(afIsError) toast.error(afError?.message);
  if(!data?.status){toast.error(data?.msg)}

  if(!afData?.status) {toast.error(afData?.msg);}

  const TotalPointData = useMemo(()=>{
    if(isError){
      return null;
    }else{
      if(!data?.status){
        return null;
      }else return {totalPoint:data.totalPoint,totalTrs:data.totalTransactions,totalProfit:data.totalProfit}
    }
  },[data,isError])
  
 
  const AfData = useMemo(()=>{
    if(afIsError) return [];
    else {
      if(!afData?.status) return [];
      else return afData.data
    }
  },[afData,afIsError])


  const topsUser =  AfData.map(item=>{
    const image = item?.profileImg?item.profileImg:people;
   
    const name = item?.userName?item?.userName:(item?.firstName+" "+item?.lastName);
    const points = item?.total_earn*100;
    return {
      img1:image,
      name:name,
      date:points.toFixed(0)
    }
  })
  
  return (
    <Layout name="headername">
      <Grid>
        <Typography className={classes.maintext}>Statistics</Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ paddingTop: { md: "67px", xs: "16px" } }}
      >
        <StatisticsCard
          isFetching={isFetching}
          img={t1}
          ratio={`${TotalPointData?TotalPointData.totalPoint.percent.toFixed(1):0}%`}
          ratioval="than last month"
          label={`${TotalPointData?TotalPointData.totalPoint.cnt.toFixed(0):0}`}
          value="Total points"
        />
        <StatisticsCard
          isFetching={isFetching}
          img={t2}
          ratio={`${TotalPointData?TotalPointData.totalTrs.percent.toFixed(1):0}%`}
          ratioval="than last month"
          label={`${TotalPointData?TotalPointData.totalTrs.cnt.toFixed(0):0}`}
          value="Total Transactions"
        />{" "}
        <StatisticsCard
          isFetching={isFetching}
          img={t3}
          ratio={`${TotalPointData?TotalPointData.totalProfit.percent.toFixed(1):0}%`}
          ratioval="than last month"
          label={`$${TotalPointData?TotalPointData.totalProfit.cnt.toFixed(1):0}`}
          value="Total Profit"
        />
      </Grid>
      <Grid container sx={{mb:"130px", marginTop: { md: "95px", xs: "24px" }, ml: "0px" }}>
        <StatisticChart method={method} isFetching={isRevFetching} setMethod={setMethod} data={RevData}/>
        <Grid
          item
          md={3.85}
          xs={12}
          sx={{
            marginLeft: { md: "20px", xs: "0px" },
            marginTop: { md: "0px", xs: "24px" },
            marginBottom: "130px",
            padding: "16px 25px",
            background: "#1B1B1B",
            borderRadius: "8px",
            gap: { sm: "17px", lg: "0px" },
            flexDirection: { sm: "column-reverse", lg: "row" },
          }}
        >
          <Grid md={12}>
            <Box justifyContent="space-between" display="flex">
              <Typography className={classes.Top}>Top Affiliated</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Box
              sx={{
                height: "50px",
                borderBottom: "1px solid  #201D16",
                flexWrap: "wrap",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                padding: {
                  md: "34px 30px 16px 30px",
                  xs: "34px 10px 16px 10px",
                },
              }}
            >
              <Box>
                <Typography color="#717171" className={classes.date}>
                  Username
                </Typography>
              </Box>
              <Box>
                <Typography color="#717171" className={classes.date}>
                  Points
                </Typography>
              </Box>{" "}
            </Box>
          </Grid>
         {
          !afIsFethcing?(
            <>
            {topsUser.map((data) => {
              return (
                <>
                    <TopAffiliate
                      img1={data.img1}
                      name={data.name}
                      date={data.date}
                    />
                  </>
                );
              })}
              </>
          ):(
            <Box sx={{width:"100%",minHeight:"300px",display:"flex",justifyContent:"center",paddingTop:"50px"}}>
                <CircularProgress  size={100}/>
              </Box>
          )
         }
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Index;
