import { useQuery } from "@tanstack/react-query";
import { getAllTransactions, getRewardPoint, getTotalEarn } from "../api/query-client";


export const useTotalEarn = ()=>{
    const queryKey =["getTotalEarn"];
    return useQuery(queryKey,async ()=>{
        const {data} = await getTotalEarn();
        return data;
    })
}
export const useReward  = ()=>{
    const queryKey = ["getRewardPoint"];
    return useQuery(queryKey,async()=>{
        const {data} = await getRewardPoint();
        return data;
    })
}
export const useLastTransaction = ()=>{
    const queryKey = ["getLatestTransaction"];
    return useQuery(queryKey,async()=>{
        const {data} = await getAllTransactions();
        return data;
    })
}