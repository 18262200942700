import { createContext, useContext, useMemo, useState } from "react";


import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useLocalStorateState } from "../hooks";
import { logOut, useResetEmail, useSignIn, useSignUp } from "../hooks/firebase";
import { useProfile } from "../hooks/useProfile";
import { useLocation } from 'react-router-dom'

export const AuthContext = createContext();
export function useAuth(){
    return useContext(AuthContext);
}
export default  function AuthProvider ({children}){
    const navigate = useNavigate();
    const location = useLocation();
    const [token,setLocalStorageState] = useLocalStorateState("token","");
    const resetEmail = useResetEmail();
    const signup = useSignUp();
    const signIn = useSignIn();

    const {isSuccess:signInSuccess} = signIn;
    const isLoginPage = location.pathname === "/" || location.pathname === "/signup";
    const {isError,data:profileData,isFetching} = useProfile();

    const isLogin = useMemo(()=>{
        if(isError || !profileData?.status) return false;
        return true;
    },[isError,profileData])

    if(!isFetching && !isLogin && !isLoginPage) { //when profile is fail    
        navigate("/");
    }else if(!isFetching && isLogin && isLoginPage){
        navigate("/overview");
    }
    const user = useMemo(()=>{
        if(profileData?.status && !isError){
            const userDB = profileData.user._doc;
            var firebaseuser  = profileData.user;
            delete firebaseuser._doc;
            delete firebaseuser["$isNew"];
            delete firebaseuser["$__"]
            delete firebaseuser.iat;
            const newUser  =  Object.assign(firebaseuser,userDB);
            return newUser;
        }else{
            return null;
        }
    },[profileData,isError])

    const data = {
        userIsFetching:isFetching,
        user:user,
        token:token,
        isLogin:isLogin,    
        signUpEmail:signup,
        signInEmail:signIn,
        resetEmail:resetEmail,
        logout:logOut
    }
    return (
        <AuthContext.Provider value={data}>
            {children}
        </AuthContext.Provider>
    )
}