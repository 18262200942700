import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Component } from "react";
import Chart from "react-apexcharts";
// import { useStyles } from "../../styles";
import CircularProgress from '@mui/material/CircularProgress';
class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching:props.isFetching,
      method : props.method,
      setMethod : props.setMethod,
      options: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998,111],
        },
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
          labels: {},
        },
      ],
    };
  }
  static getDerivedStateFromProps(props, state) {
    state.isFetching = props.isFetching;
    if(props.method==="month"){
      const data = props.data.map((item)=>(item*100).toFixed(3));
       return {method: props.method ,series:[{  name: "series-1",data:data,labels: {},}], options:{...state.options, xaxis: {categories:Index.makeLabels(props.method)}}};
    }
     if(props.method === "week" ||props.method === "year" ){
        let labels = [];let data = [];
       for (const [key, value] of Object.entries(props.data)) {
           labels.push(key);
           data.push(value*100);
       }
       return {method: props.method ,series:[{  name: "series-1",data:data,labels: {},}],   options:{...state.options, xaxis: {categories:labels}}};
     }
   }
   static makeLabels=(method)=>{
     if(method === "month"){
       const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","De"];
       let result=[]
       for(let i=0;i<12;i++){
         result[i] = `${i+1} ${month[i]}`
       }
       
       return result;
     }
     return []
   }
  render() {
    return (
      <Grid
        item
        md={5.9}
        xs={12}
        mt={{ md: "0px", xs: "25px" }}
        ml={{ md: "20px", xs: "0px" }}
      >
        <div
          style={{
            backgroundColor: "#1B1B1B",
            height: "auto",
            borderRadius: "8px",
            padding: "0px 20px 0px 0px",
          }}
          className="app"
        >
          <Box
            sx={{
              pt: "20px",
              ml: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent:"space-between"
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: "500 !important",
                  fontFamily: "Inter !important",
                  fontSize: "14px !important",
                  lineHeight: "21px!important",
                }}
              >
                Points Earning
              </Typography>
              {/* </h4> */}
            </Box>
            <Box >
              <Select
                value={this.state.method}
                onChange={(e)=>this.state.setMethod(e.target.value)}
              
                disableUnderline
                variant="standard"
                sx={{
                  height: "28px",
                  fontSize: "12px",
                  fontWight: 600,
                  borderRadius: "5px",
                  color: "#BCBBB9",
                  width: "75px",
                  padding:"0px 5px",
                  border: "1px solid #252525",
                  ".MuiSelect-icon": {
                    color: "white",
                  },
                }}
              >
                 <MenuItem value={"week"}>Week</MenuItem>
                  <MenuItem value={"month"}>Month</MenuItem>
                  <MenuItem value={"year"}>Year</MenuItem>
              </Select>
            </Box>
          </Box>
          <div className="row">
            <div className="mixed-chart">
            {
              !this.state.isFetching?(
                <Chart
                  options={this.state.options}
                  series={this.state.series}
                  type="bar"
                  height="200px"
                />
              ):(
                <Box sx={{width:"100%",minHeight:"220px",display:"flex",justifyContent:"center",paddingTop:"20px"}}>
                  <CircularProgress  size={70}/>
                </Box>
              )
            }
            </div>
          </div>
        </div>
      </Grid>
    );
  }
}

export default Index;
