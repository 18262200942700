import { balance } from "../imageSource";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  //  login css
  titless: {
    paddingLeft: "5px !important",
    fontFamily: "Inter !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "19px !important",

    [theme.breakpoints.down("sm")]: {},
  },
  // componentCss
  // amountcard css

  amountcard: {
    backgroundColor: "#1B1B1B",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "24px",
  },
  amountcard2: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "24px",
  },
  staticCard: {
    backgroundColor: "#1B1B1B",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    padding: "24px",
  },
  amount1: {
    fontFamily: "Inter !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    color: "white !important",
    lineHeight: "150% !important",
  },
  total: {
    fontFamily: "Inter",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    color: "#BCBBB9 !important",
    lineHeight: " 150% !important",
  },
  ratioval: {
    fontFamily: "Inter !important",
    fontWeight: "500 !important",
    marginTop: "24px !important",
    fontSize: "14px !important",
    color: "#717171 !important",
    lineHeight: " 150%  !important",
  },
  totaP: {
    fontFamily: "Inter",
    fontWeight: "500",
    marginTop: "8px",
    fontSize: "29px !important",
    lineHeight: " 120%",
  },
  topaffiliate: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "12px !important",
    lineHeight: " 150%",
  },
  aDuration: {
    fontFamily: "Inter !important",
    fontWeight: "600 !important",
    marginTop: "8px !important",
    fontSize: "14px !important",
    color: "#BCBBB9 !important",
    lineHeight: " 150% !important",
  },
  rewards: {
    marginTop: "8px",
    borderRadius: "8px",
    background: "#1B1B1B",
    padding: "20px",
    color: "white !important",
    height: "347px",
  },
  level: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },

  // leftbar
  lTitle: {
    fontFamily: "Inter",
    fontWeight: "700",
    marginLeft: "5px",
    color: "white",
    fontSize: "13.72px",
    lineHeight: "24.94px",
  },

  TitleDiv: {
    display: "flex",
    paddingLeft: "30px",
    flexDirection: "row",
    alignItems: "center",
  },

  // peoplecss
  uname: {
    fontFamily: "Inter",
    fontWeight: "600",
    paddingLeft: "8px",
    fontSize: "12px",
    lineHeight: "18px",
  },
  unameinfo: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "18px",
  },
  earned: {
    fontFamily: "Inter",
    fontWeight: "600",
    paddingLeft: "8px",
    fontSize: "12px",
    lineHeight: "18px",
  },
  point: {
    paddingTop: "15px",
    paddingLeft: "8px",
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "21px",
  },
  about: {
    fontFamily: "Inter",
    fontWeight: "400",
    marginTop: "4px",
    fontSize: "10px",
    lineHeight: "15px",
  },

  faqs: {
    color: "white",
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "24px !important",
    lineHeight: "150% !important",
  },
  typeof: {
    color: "#717171",
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "12px !important",
    lineHeight: "18px !important",
  },
  // balance
  balancecard: {
    backgroundImage: `url(${balance})`,
    // width: "360px",
    backgroundRepeat: "no-repeat",
    height: "266px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${balance})`,
      width: "350px",
      marginLeft: "20px",
      backgroundSize: "contain",
      transform: "scale(1.1) !important",
      backgroundRepeat: "no-repeat",
      height: "266px",
    },
  },
  amount: {
    marginTop: "18px",
    color: "#141414 ",
    fontWeight: "600 !important",
    fontFamily: "Inter !important",
    fontSize: "29px !important",
    lineHeight: "36.8px !important",
  },
  available: {
    marginTop: "8px",
    paddingBottom: "36px",
    color: "#141414",
    fontWeight: "600 !important",
    fontFamily: "Inter !important",
    fontSize: "12px !important",
    lineHeight: "150% !important",
  },
  Withdrow: {
    width: "360px ",
    height: "305px",
    color: "#ffff",
    display: "flex",
    flexDirection: "column",
    background: "#1B1B1B",
    padding: "19px",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      width: "360px",
      marginLeft: "15px",
      transform: "scale(1.07) !important",
    },
  },
  WithdrowR: {
    width: "360px",
    height: "305px",
    color: "#ffff",
    display: "flex",
    flexDirection: "column",
    background: "#1B1B1B",
    padding: "19px",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      width: "360px",
      marginLeft: "15px",
      transform: "scale(1.07) !important",
    },
  },
  con1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 0px 10px 0px",
    borderRadius: "8px",
  },
  con11: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "25px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0px 0px 0px",
    },
  },
  con2: {
    padding: "10px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "8px",
  },
  lw: {
    color: "white",
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "18px !important",
    lineHeight: "27px !important",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Inter !important",
      fontWeight: "500 !important",
      fontSize: "14px !important",
      lineHeight: "21px !important",
    },
  },
  date: {
    color: "#BCBBB9",
    fontWeight: "600 !important",
    fontFamily: "Inter !important",
    fontSize: "12px !important",
    lineHeight: "150% !important",
  },
  Withdrowlist: {
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "12px !important",
    lineHeight: "150% !important",
    textAlign: "start",
  },
  congrats: {
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "17px !important",
    lineHeight: "150% !important",
  },
  // transaction
  transaction: {
    color: "#ffff",
    display: "flex",
    flexDirection: "column",
    background: "#1B1B1B",
    borderRadius: "8px",
    padding: "12px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
    },
  },
  transactiontext: {
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "18px !important",
    lineHeight: "27px !important",
  },
  theader: {
    color: "#71717",
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "14px !important",
    lineHeight: "150% !important",
  },
  accordtext: {
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "14px !important",
    lineHeight: "21px !important",
  },
  view: {
    color: "#BCBBB9",
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "14px !important",
    lineHeight: "150% !important",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Inter !important",
      fontWeight: "500 !important",
      fontSize: "12px !important",
      lineHeight: "150% !important",
    },
  },
  still: {
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "24px !important",
    lineHeight: "36px !important",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500 !important",
      fontFamily: "Inter !important",
      fontSize: "19px !important",
      lineHeight: "28.5px !important",
    },
  },
  getText: {
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "24px !important",
    lineHeight: "34px !important",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500 !important",
      fontFamily: "Inter !important",
      fontSize: "18px !important",
      lineHeight: "27px !important",
    },
  },
  modal1text: {
    fontFamily: "Inter !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "21px !important",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Inter !important",
      fontWeight: "500 !important",
      fontSize: "10px !important",
      lineHeight: "18px !important",
    },
  },
  inputlable: {
    fontFamily: "Inter !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "21px !important",
  },
  modal2text: {
    fontFamily: "Inter !important",
    fontWeight: "400 !important",
    fontSize: "10px !important",
    lineHeight: "15px !important",
  },

  modalcard: {
    cursor: "pointer",
    marginTop: "32px",
    marginRight: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "140.22px",
    textAlign: "center",
    alignItems: "center",
    height: "125.21px",
    background: "#252525",
    borderRadius: "4.99531px",
    [theme.breakpoints.down("sm")]: {
      width: "93px",
      cursor: "pointer",
      marginRight: "5px",
      height: "auto",
    },
  },
  modal3text: {
    fontFamily: "Inter !important",
    fontWeight: "500 !important",
    fontSize: "29px !important",
    lineHeight: "34.5px !important",
  },
  modal4text: {
    fontFamily: "Inter !important",
    fontWeight: "500 !important",
    fontSize: "24px !important",
    lineHeight: "36px !important",
  },
  mnumberbox: {
    marginRight: "8px",
    background: "#252525",
    borderRadius: "8px",
    width: "60px",
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginRight: "8px",
      background: "#252525",
      borderRadius: "8px",
      width: "42px",
      height: "55px",
    },
  },
  headeruser: {
    fontFamily: "Inter !important",
    fontWeight: "500 !important",
    fontSize: "12px !important",
    lineHeight: "18px !important",
  },

  headeruserdes: {
    fontFamily: "Inter !important",
    fontWeight: "500 !important",
    fontSize: "10px !important",
    color: "#BCBBB9 !important",
    lineHeight: "18px !important",
  },
  leveltext: {
    fontFamily: "Inter !important",
    fontWeight: "500 !important",
    fontSize: "20px !important",
    color: "white !important",
    lineHeight: "30px !important",
  },
  leveltext2: {
    fontFamily: "Inter !important",
    fontWeight: "500 !important",
    fontSize: "17px !important",
    lineHeight: "150% !important",
  },

  graphtext: {
    color: "white",
    mt: "22px",
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "20px !important",
    lineHeight: "30px !important",
    [theme.breakpoints.down("sm")]: {
      color: "white",
      mt: "24px",
      fontFamily: "Inter !important",
      fontWeight: "500 !important",
      fontSize: "16px !important",
      lineHeight: "24px !important",
    },
  },
  Top: {
    color: "white",
    mt: "22px",
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "18px !important",
    lineHeight: "27px !important",
    [theme.breakpoints.down("sm")]: {
      color: "white",
      mt: "24px",
      fontFamily: "Inter !important",
      fontWeight: "500 !important",
      fontSize: "14px !important",
      lineHeight: "21px !important",
    },
  },

  rep: {
    fontWeight: "500 !important",
    fontFamily: "Inter !important",
    fontSize: "14px !important",
    lineHeight: "27px !important",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Inter !important",
      fontWeight: "500 !important",
      fontSize: "12px !important",
      lineHeight: "18px !important",
    },
  },
  pointsname: {
    marginTop: "7px !important",
    fontFamily: "Inter !important",
    fontWeight: "500 !important",
    fontSize: "12px !important",
    lineHeight: "18px !important",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Inter !important",
      fontWeight: "500 !important",
      fontSize: "12px !important",
      lineHeight: "18px !important",
    },
  },
  maintext: {
    borderBottom: "1px solid #252525",
    padding: "22px 0px",
    fontFamily: "Inter !important",
    fontWeight: "500 !important",
    fontSize: "20px !important",
    lineHeight: "18px !important",
    [theme.breakpoints.down("sm")]: {
      borderBottom: "none",
      padding: "0px 0px",
      fontFamily: "Inter !important",
      fontWeight: "500 !important",
      fontSize: "16px !important",
      lineHeight: "24px !important",
    },
  },

  mbtitle: {
    marginTop: "2px !important",
    fontFamily: "Inter !important",
    fontWeight: "500 !important",
    fontSize: "12.77px !important",
    lineHeight: "23.22px !important",
  },
  elipstext: {
    lineHeight: "18px !important",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px !important",
      width: "45% !important",
      textOverflow: "ellipsis" /* enables ellipsis */,
      whiteSpace: "nowrap" /* keeps the text in a single line */,
      overflow: "hidden" /* keeps the element from overflowing its parent */,
    },
  },
  checkbox: {
    // color: "#FCBF07 !important",
  },
  referral: {
    fontFamily: "Inter !important ",
    fontWeight: "800 !important ",
    fontSize: "47px !important ",
    lineHeight: "72px !important ",
    [theme.breakpoints.down("sm")]: {
      color: "white !important ",
      fontFamily: "Inter !important ",
      fontWeight: "500 !important ",
      fontSize: "24px !important ",
      lineHeight: "34px !important ",
    },
  },
  resetemail: {
    fontFamily: "Inter !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "19.36px !important",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
      lineHeight: "14.52px !important",
    },
  },

  verification: {
    fontFamily: "Inter !important",
    fontWeight: "400 !important",
    fontSize: "30px !important",
    lineHeight: "36.31px !important",

    [theme.breakpoints.down("sm")]: {
      width: "239px",
      textAlign: "center",
      fontSize: "20px !important",
      lineHeight: "24.2px !important",
    },
  },
}));
