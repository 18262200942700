import { Grid, Typography } from "@mui/material";
import React from "react";
import { AppInput, Button } from "../../components";
import logo from "../../assets/images/loginlogo.png";
import { Box } from "@mui/system";
import { useStyles } from "../../styles";
import { Link } from "react-router-dom";
const Index = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      sx={{
        pt: "30px",
        height: { md: "103vh", xs: "105vh" },
        pb: { md: "0px", xs: "160px" },
        overflow: { md: "none", xs: "scroll !important" },
        background: { md: "#1B1B1B", xs: "#1B1B1B" },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        sx={{
          color: "white",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            mb: "124px",
            justifyContent: "center",
          }}
        >
          <Box display={{ md: "flex", xs: "none" }} mr="11.98px">
            <img src={logo} alt="" />
          </Box>
          <Box display={{ md: "none", xs: "flex" }} mr="11.98px">
            <img src={logo} alt="" width="42.5px" height="39.52px" />
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: { md: "29.273px", xs: "19.31px" },
              lineHeight: "53px",
            }}
          >
            Flash Affiliate
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "19px",
            }}
          >
            Email Address
          </Typography>

          <Box width={{ md: "563.29px", xs: "335px" }}>
            <AppInput
              margin={"12.62px 0px 0px 0px"}
              // width="563.29px"
              borderRadius={"10px"}
              height="57.44px"
              color="white"
              placeholder="example@gmail.com"
              backgroundColor="#161616"
            />
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box mt="38px">
            <Typography className={classes.resetemail}>
              Please enter your email address to reset your password.
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          md={12}
          sx={{
            mt: { md: "41px", xs: "197px" },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Link to="/pendingaccount" style={{ textDecoration: "none" }}>
            <Box width={{ md: "354.29px", xs: "335px" }}>
              <Button
                // width="354.29px"
                textTransform="none"
                label="Send an email"
                border="none"
                height="57.44px"
                bg="#FBBF04"
                color={"black"}
              />
            </Box>
          </Link>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            mt: { md: "40px", xs: "16px " },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: "#BCBBB9",
              fontFamily: "Inter",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "19px",
            }}
          >
            Don’t have account yet?
          </Typography>
          <Link to="/Signup" style={{ textDecoration: "none" }}>
            <Typography
              sx={{
                paddingLeft: "10px",
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#FCC005",
              }}
            >
              New Account
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Index;
