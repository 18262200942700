import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, Modal6 } from "..";
import { close, userimg } from "../../imageSource";
import { useStyles } from "../../styles";
import "./index.css";
import { useWithdrow } from "../../context/withdrow_context";
import CircularProgress from "@mui/material/CircularProgress";
import { useTimer } from "react-timer-hook";
import { toast } from "react-toastify";
import { useResendSMSForWithdrow } from "../../hooks/withdrow";
import OtpInput from "react-otp-input";

export class Otp extends React.Component {
  state = { otp: "" };

  handleChange = (otp) => this.setState({ otp });

  render() {
    return (
      <OtpInput
        value={this.state.otp}
        onChange={this.handleChange}
        numInputs={6}
        inputStyle="inputStyle"
        separator={<span className="spantag">-</span>}
        className="otp"
      />
    );
  }
}
export default function BasicModal({ name }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { isLoading, handleWithdrow, IsError, Error, Data, setTrId, trId } =
    useWithdrow();
  const { seconds, minutes, restart, isRunning } = useTimer({
    expiryTimestamp: new Date().setSeconds(new Date().getSeconds() + 300),
    onExpire: () => {},
  });
  const [num1, setNum1] = React.useState("");
  const resendHandler = useResendSMSForWithdrow();
  const {
    isLoading: isResendLoading,
    data: resendData,
    isError: isResendError,
  } = resendHandler;
  const handleContinue = () => {
    handleWithdrow();
  };

  const handleResend = React.useCallback(() => {
    resendHandler.mutate(trId);
  }, [trId]);
  React.useEffect(() => {
    if (resendData?.data?.status) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + 300);
      restart(time);
    }
  }, [resendData]);
  React.useEffect(() => {
    if (Data?.data?.status) {
      setOpen(true);
      setTrId(Data?.data?.id);
      const time = new Date();
      time.setSeconds(time.getSeconds() + 300);
      restart(time);
    }
  }, [Data]);
  const classes = useStyles();

  return (
    <div>
      {name ? (
        <Button
          // width="442px"
          textTransform="capitalize"
          label="Continue"
          height="41px"
          bg="#FBBF04"
          border="none"
          color={"#151103"}
          isLoading={isLoading}
          onClick={handleContinue}
        />
      ) : (
        <Box
          width={{ md: "442px", xs: "303px" }}
          ml={{ md: "0px", xs: "-8px" }}
        >
          <Button
            textTransform="capitalize"
            label="Withdraw"
            border="none"
            height="41px"
            isLoading={isLoading}
            bg="#FBBF04"
            color={"#151103"}
            onClick={handleContinue}
          />
        </Box>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        hideBackdrop={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isLoading ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              p: "25px",
              transform: "translate(-50%, -50%)",
              background: "#1B1B1B",
              borderRadius: "8px",
              flexDirection: "column",
              display: "flex",
              width: { md: "492px", xs: "335px" },
              height: "611px",
            }}
          >
            <Box
              pb="10px"
              borderBottom="1px solid #252525"
              display="flex"
              justifyContent="space-between"
            >
              <Typography className={classes.transactiontext}>
                Withdraw Money
              </Typography>
              <img src={close} alt="close" onClick={handleClose} />
            </Box>
            <Typography mt="32px" className={classes.modal1text}>
              To
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt="12px"
              p="7px"
              borderRadius="8px"
              border="1px solid #252525"
            >
              <Box display="flex">
                <Box mr="12px">
                  <img src={userimg} alt="" />
                </Box>
                <Box>
                  <Typography className={classes.modal1text}>
                    John Smith
                  </Typography>
                  <Typography color="#717171" className={classes.Withdrowlist}>
                    johnsmith@gmail.com
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="10px"
            >
              <Box>
                <Typography mt="16px" className={classes.accordtext}>
                  Amount
                </Typography>
                <Typography color="#717171" className={classes.Withdrowlist}>
                  The amount will be transfer to your account
                </Typography>
              </Box>
              <Box display={{ md: "flex", xs: "none" }}>
                <Typography className={classes.modal4text}>$500.00</Typography>
              </Box>
            </Box>
            <Box borderTop="1px solid #252525">
              <Typography mt="16px" className={classes.accordtext}>
                Enter your verification code
              </Typography>
              <Typography color="#717171" className={classes.Withdrowlist}>
                We have sent 6-digit code to your phone number as stated below
                +564 88****87 the code will expire in 5 minutes. contact your
                sponsor if you have trouble getting the code.
              </Typography>
            </Box>
            <Box display="flex" mt="32px" justifyContent="center">
              <Otp />
              {/* <Box
                sx={{
                  mr: "8px",
                  background: "#252525",
                  borderRadius: "8px",
                  width: { md: "60px", xs: "42px" },
                  height: { md: "80px", xs: "55px" },
                }}
              ></Box>
              <Box
                sx={{
                  mr: "8px",
                  background: "#252525",
                  borderRadius: "8px",
                  width: { md: "60px", xs: "42px" },
                  height: { md: "80px", xs: "55px" },
                }}
              ></Box>{" "} */}
              {/* <Box
                sx={{
                  mr: "8px",
                  background: "#252525",
                  borderRadius: "8px",
                  width: { md: "60px", xs: "42px" },
                  height: { md: "80px", xs: "55px" },
                }}
              ></Box>{" "}
              <Box
                sx={{
                  mr: "8px",
                  background: "#252525",
                  borderRadius: "8px",
                  width: { md: "60px", xs: "42px" },
                  height: { md: "80px", xs: "55px" },
                }}
              ></Box>{" "}
              <Box
                sx={{
                  mr: "8px",
                  background: "#252525",
                  borderRadius: "8px",
                  width: { md: "60px", xs: "42px" },
                  height: { md: "80px", xs: "55px" },
                }}
              ></Box>{" "}
              <Box
                sx={{
                  mr: "8px",
                  background: "#252525",
                  borderRadius: "8px",
                  width: { md: "60px", xs: "42px" },
                  height: { md: "80px", xs: "55px" },
                }}
              ></Box> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: { md: "auto", xs: "110.15px" },
                }}
              >
                <Typography
                  textAlign="center"
                  color="#717171"
                  mt="26px"
                  className={classes.Withdrowlist}
                >
                  Code expires in {minutes}:{seconds}
                </Typography>
              </Box>
            </Box>
            <Box mt={{ md: "24px", xs: "12px" }}>
              <Modal6 />
              {/* <Button
                  width="442px"
                  textTransform="capitalize"
                  label="Withdraw"
                  border="none"
                  height="41px"
                  bg="#FBBF04"
                  color={"#151103"}
                  onClick={handleOpen}
                /> */}
            </Box>
            <Box justifyContent="center" display="flex">
              <Box
                mt="16px"
                width={{ md: "auto", xs: "110.15px" }}
                textAlign="center"
              >
                <Typography
                  color="#BCBBB9"
                  textAlign="center"
                  className={classes.Withdrowlist}
                >
                  Didn’t get a code?
                  <span style={{ color: "#FBBF04" }} onClick={handleResend}>
                    {" "}
                    Resend
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <CircularProgress />
        )}
      </Modal>
    </div>
  );
}
