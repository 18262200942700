import { Grid, Box, Typography } from "@mui/material";
// import Switch, { SwitchProps } from "@mui/material/Switch";
import React from "react";
import { Button } from "..";
import { useStyles } from "../../styles";

const Index = ({ arrow, name, date, img1, img2 }) => {
  const classes = useStyles();
  return (
    <Grid>
      <Box
        sx={{
          borderTop: "1px solid #292929",
          height: "62px",
          padding: "10px 0px",
          flexWrap: "wrap",
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box mr="10px">
            <img src={img1} width={30} alt="" style={{borderRadius:"100px"}}/>
          </Box>
          <Typography mt="5px" className={classes.topaffiliate}>
            {name}
          </Typography>
        </Box>
        <Box mr="15px">
          <img src={img2} alt="" />
        </Box>
        <Box display="flex" alignItems="center">
          <Box mr="10px" >
            <img src={arrow} alt="" />
          </Box>
          <Typography className={classes.topaffiliate}>{date}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Index;
