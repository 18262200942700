import "./App.css";
import AppRouter from "./navigation";
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
