import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import { createTheme, ThemeProvider } from "@mui/material/styles";

import {createTheme} from '@mui/material';
import {ThemeProvider} from '@mui/styles';
import "bootstrap/dist/css/bootstrap.min.css";
import { CssBaseline } from "@mui/material";
import "bootstrap/dist/js/bootstrap.bundle";
import "./index.css";
import AuthProvider from "./context/auth_context";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { queryClient } from "./api/query-client";
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';


const theme = createTheme();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client = {queryClient}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
             <App />
           
            <ToastContainer />
          </ThemeProvider>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={true}/>
      </QueryClientProvider >
    </BrowserRouter>
  </React.StrictMode>
);
