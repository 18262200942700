import {QueryClient} from '@tanstack/react-query';
import axios, {AxiosRequestConfig} from 'axios';
import { api_url, base_url ,host_url} from './config';

export const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 30000,
      },
    },
  });
export const apiClient = axios.create();
apiClient.defaults.responseType = 'json';


apiClient.defaults.headers = {
    withCredentials:false,
    common: {
      Accept: 'application/json',
    },
    
};
apiClient.interceptors.request.use(config=>{
  if(config.url==="/createAffiliator"){
    config.url = host_url+`/api/${config.url}`;
  }else{
    config.url =base_url+`/${config.url}`;
  }
  
  config.headers.authorization="Bearer "+JSON.parse(localStorage.getItem("token"))
  return config
})
export const getProfile =async ()=>{
  return apiClient.get(api_url.getUser);
}
export const getTotalEarn = async ()=>{
  return apiClient.get(api_url.totalEarn);
}
export const getIncomeProgress = async ()=>{
  return apiClient.get(api_url.incomeProgress);
}
export const getRewardPoint = async ()=>{
  return apiClient.get(api_url.reward);
}
export const getTotalPoint  = async ()=>{
  return apiClient.get(api_url.totalPoint)
}
export const getTopAffiliators = async ()=>{
  return apiClient.get(api_url.rankingAffiliators);
}
export const getRevenue = async (data)=>{
  return apiClient.get(api_url.revene,{params:data});
}
export const ranking = async ()=>{
  return apiClient.get(api_url.ranking);
}
export const withdrowCrypto = async (data)=>{
  return apiClient.post(api_url.withdrowCrypto,data);
}
export const withdrowBank = async (data)=>{
  return apiClient.post(api_url.withdrowBank,data);
}
export const withdrowPaypal = async (data)=>{
  return apiClient.post(api_url.withdrowPaypal,data);
}
export const getAllTransactions = async ()=>{
  return apiClient.get(api_url.transactions);
}
export const getAllCommision = async ()=>{
  return apiClient.get(api_url.commisions);
}
export const updateProfileImg = async (data)=>{
  return apiClient.post(api_url.updateProfileImg,data);
}
export const updateProfile = async (data)=>{
  return apiClient.post(api_url.updateProfile,data);
}
export const createAffiliator  = (data)=>{
  return apiClient.post(api_url.createAffiliator,data);
}
export const checkKYCVerification =async ()=>{
  return apiClient.get(api_url.checkKYCVerification);
}
// export const verifyOTP = async (data)=>{
//   return apiClient.post(api_url.verifyOTP,data);
// }
export const verifySMSForWithdrow = (data)=>{
  return apiClient.post(api_url.verifySMS,data);

}
export const reSendSMSForWithdrow = (data)=>{
  return apiClient.post(api_url.resendSMS,data)
}
export const getWithdrowHistory = ()=>{
  return apiClient.get(api_url.getWithdrowHis)
} 
export const getLatestWithdrowHistory = ()=>{
  return apiClient.get(api_url.getLatestWidHistory);
}
export const getTopAffilitedUser = ()=>{
  return apiClient.get(api_url.getTopAffilitedUser);
}
export const getBalance = ()=>{
  return apiClient.get(api_url.balance);
}
export const getTopRevenu = (data)=>{
  return apiClient.get(api_url.topRevene,{params:data});
}
