import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { checkKYCVerification, getBalance, getLatestWithdrowHistory, getWithdrowHistory, reSendSMSForWithdrow, verifyOTP, verifySMSForWithdrow, withdrowBank, withdrowCrypto, withdrowPaypal } from "../api/query-client";

export const useCheckKYCVerification = ()=>{
    const queryKey = ["KYCVerificationCheck"];
    return useQuery(queryKey,async ()=>{
        const {data} = await checkKYCVerification();
        return data;   
    })
}

export const usePayPal = ()=>{
    return useMutation(({paypal_email,amount})=>withdrowPaypal({paypal_email,amount}),{
        onError:(error,val,context)=>{
            toast.error(error?.message);
        }
    })
}
export const useCrypto = ()=>{
    return useMutation(({amount,crypto_address})=>withdrowCrypto({amount,crypto_address}),{
        onError:(error,val,context)=>{
            toast.error(error?.message);
        }
    })
}

export const useBankTransfer = ()=>{
    return useMutation(({bank_name,holder,iban,bic,amount})=>withdrowBank({bank_name,holder,iban,bic,amount}),{
        onError:(error,val,context)=>{
            toast.error(error?.message);
        }
    })
}
export const useBalance = ()=>{
    const queryKey = ["getBalance"];
    return useQuery(queryKey,async function(){
        const {data} =await getBalance();
        return data;
    })
}
export const useVerifySMSForWithdrow = ()=>{
    return useMutation(({id,smsCode})=>verifySMSForWithdrow({id,smsCode}),{
        onError:(error,value,context)=>{
            toast.error(error?.message);
        }
    })
}
export const useResendSMSForWithdrow = ()=>{
    return useMutation((id)=>reSendSMSForWithdrow({id}),{
        onError:(error,data,context)=>{
            toast.error(error?.message);
        }
    })
}
export const useWithdrowHistory = ()=>{
    const queryKey = ["getWithdrowHistory"];
    return useQuery(queryKey,async ()=>{
        const {data} =await getWithdrowHistory();
        return data;
    })
}
export const useLatestWithdrowHistory  = ()=>{
    const querykey = ['getLatestWithdrowHistory'];
    return useQuery(querykey,async()=>{
        const {data} = await getLatestWithdrowHistory();
        return data;
    })
}
