import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";

import imgLogo from "../../assets/images/logo.png";

import styles from "./style.module.css";
import { Box,  Typography } from "@mui/material";
import { useStyles } from "../../styles";
import { useLocation } from "react-router-dom";

import {
  CommissionB,
  CommissionW,
  helpB,
  helpW,
  leaderB,
  leaderW,
  logout,
  overviewB,
  overviewW,
  peopleB,
  peopleW,
  settingB,
  settingW,
  staticB,
  staticW,
  userimg,
  walletB,
  walletW,
} from "../../imageSource";
import {  useSignOut } from "../../hooks/firebase";
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from "../../context/auth_context";
const Index = ({ mobile }) => {
  const location = useLocation();
  
  const classes = useStyles();
  const [value, setValue] = useState(true);
  const handleSignout = useSignOut();
  const {isLoading,isError}=handleSignout;
  const [pathname, setPathname] = useState("/");
  const {user} = useAuth();
  const handleLogout =  ()=>{
      handleSignout.mutate();
  }
  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  return (
    <Box className={styles.mainContainer}>
      <Box mt="22px">
        <Box className={classes.TitleDiv}>
          <Box>
            <img src={imgLogo} alt="Flash Technology" />
          </Box>
          <Typography className={classes.lTitle} ml="10px">
            Flash Affiliate
          </Typography>
        </Box>

        <Box className={styles.linkContainer}>
          <Box>
            <NavLink
              to="/Overview"
              className={({ isActive }) =>
                isActive ? styles.activeLink : styles.inActiveLink
              }
            >
              {pathname === "/Overview" ? (
                <img src={overviewB} alt="Home" className={styles.imgBar} />
              ) : (
                <img src={overviewW} alt="Home" className={styles.imgBar} />
              )}

              {mobile > 600 ? "Overview" : ""}
            </NavLink>
          </Box>
          <Box onClick={() => setValue(!value)}>
            <NavLink
              to="/Statistics"
              className={({ isActive }) =>
                isActive ? styles.activeLink : styles.inActiveLink
              }
            >
              {pathname === "/Statistics" ? (
                <img src={staticB} alt="Home" className={styles.imgBar} />
              ) : (
                <img src={staticW} alt="Home" className={styles.imgBar} />
              )}

              {mobile > 600 ? "Statistics" : ""}
            </NavLink>
          </Box>

          <Box onClick={() => setValue(!value)}>
            <NavLink
              to="/people"
              className={({ isActive }) =>
                isActive ? styles.activeLink : styles.inActiveLink
              }
            >
              {pathname === "/people" ? (
                <img src={peopleB} alt="Home" className={styles.imgBar} />
              ) : (
                <img src={peopleW} alt="Home" className={styles.imgBar} />
              )}

              {mobile > 600 ? "People" : ""}
            </NavLink>
          </Box>
          <NavLink
            to="/Leaderboard"
            className={({ isActive }) =>
              isActive ? styles.activeLink : styles.inActiveLink
            }
          >
            {pathname === "/Leaderboard" ? (
              <img src={leaderB} alt="Home" className={styles.imgBar} />
            ) : (
              <img src={leaderW} alt="Home" className={styles.imgBar} />
            )}
            {/* {value ? (
              <img src={leaderW} alt="Profile" className={styles.imgBar} />
            ) : (
              // <FaUserFriends className={styles.imgBar} />
              <img src={leaderB} alt="Staking" className={styles.imgBar} />
            )} */}
            {/* <MdLeaderboard className={styles.imgBar} /> */}

            {/* <img src={setting} alt="Profile" className={styles.imgBar} /> */}
            {mobile > 600 ? "Leaderboard" : ""}
          </NavLink>
          <NavLink
            to="/Commissions"
            className={({ isActive }) =>
              isActive ? styles.activeLink : styles.inActiveLink
            }
          >
            {pathname === "/Commissions" ? (
              <img src={CommissionB} alt="Home" className={styles.imgBar} />
            ) : (
              <img src={CommissionW} alt="Home" className={styles.imgBar} />
            )}

            {mobile > 600 ? "Commissions" : ""}
          </NavLink>
          <NavLink
            to="/Wallet"
            className={({ isActive }) =>
              isActive ? styles.activeLink : styles.inActiveLink
            }
          >
            {pathname === "/Wallet" ? (
              <img src={walletB} alt="Home" className={styles.imgBar} />
            ) : (
              <img src={walletW} alt="Home" className={styles.imgBar} />
            )}
            {mobile > 600 ? "Wallet" : ""}
          </NavLink>
          <NavLink
            style={{ marginTop: "128px" }}
            to="/Setting"
            className={({ isActive }) =>
              isActive ? styles.activeLink : styles.inActiveLink
            }
          >
            {pathname === "/Setting" ? (
              <img src={settingB} alt="Home" className={styles.imgBar} />
            ) : (
              <img src={settingW} alt="Home" className={styles.imgBar} />
            )}
            {mobile > 600 ? "Settings" : ""}
          </NavLink>
          <NavLink
            to="/Help"
            className={({ isActive }) =>
              isActive ? styles.activeLink : styles.inActiveLink
            }
          >
            {pathname === "/Help" ? (
              <img src={helpB} alt="Home" className={styles.imgBar} />
            ) : (
              <img src={helpW} alt="Profile" className={styles.imgBar} />
            )}
            {mobile > 600 ? "Help" : ""}
          </NavLink>
       
            <Box onClick={handleLogout}
              sx={{
                m: "180px 20px 0px 0px",
                background: "#252525",
                borderRadius: "8px",
                display: "flex",
                padding: "15px 10px",
                alignItems: "center",
              }}
            >
             {
              !isLoading?(
                <>
                  <img width={42} src={user?.profileImg?user?.profileImg:userimg} alt="" style={{borderRadius:"100px"}}/>
                  <Box ml="10px">
                    <Typography className={classes.headeruser}>
                     { user?user.firstName+" " + user.lastName:"Easin Arafat"}
                    </Typography>
                    <Typography className={classes.headeruserdes}>
                      Affiliator
                    </Typography>
                  </Box>
                  <Box ml="10px" >
                    <img src={logout} alt="" />
                  </Box>
                </>
              ):
              (
                <CircularProgress />
              )
             }
            </Box>
      
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
