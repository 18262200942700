import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {  Button } from "..";
import {  close, congrats, copyicon } from "../../imageSource";
import { useStyles } from "../../styles";

export default function BasicModal({item}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  
  return (
    <div>
      <Box mt="-10px">
        <Button
          label={item?.btn}
          textTransform="none"
          bg="#141414"
          color={item.color}
          border={item.border}
          height={"41px"}
          borderRadius="8px"
          width="133px"
          onClick={handleOpen}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        // hideBackdrop={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            p: "25px",
            transform: "translate(-50%, -50%)",
            background: "#1B1B1B",
            borderRadius: "8px",
            flexDirection: "column",
            display: "flex",
            width: "492px",
            height: "auto",
          }}
        >
          <Box
            sx={{
              background: "#1B1B1B",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          ></Box>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.lw} variant="h6" component="h2">
              Withdraw Money
            </Typography>
            <img src={close} alt="close" onClick={handleClose} />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              flexDirection: "column",
              mt: "32px",
            }}
          >
            <Box mr="12px">
              <img src={congrats} alt="" />
            </Box>
            <Box
              sx={{
                mt: "20px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography className={classes.congrats}>
                Congratulations
              </Typography>
              <Typography
                color="#BCBBB9"
                textAlign="center"
                className={classes.Withdrowlist}
              >
                Your withdrawal request is taken into account and your money
                will be transferred to you account in 1-2 business days
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography mt="16px" className={classes.modal1text}>
              {item?.updatedAt}
              </Typography>
              <Box display="flex" alignItems={"center"}>
                <Typography color="#717171" className={classes.Withdrowlist}>
                  Trans ID {item?.invoice}
                </Typography>
                <Box ml="5px">
                  <img src={copyicon} alt="" />
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="end" flexDirection="column">
              <Typography className={classes.modal1text}>${item?.amount}</Typography>
              <Typography color={item?.color}className={classes.Withdrowlist}>
                {item?.btn}
              </Typography>
            </Box>
          </Box>
          <Box mt="26px">
            <Typography className={classes.modal1text}>
              Account Details
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                mt="16px"
                color="#BCBBB9"
                className={classes.modal1text}
              >
                Bank
              </Typography>
              <Typography
                mt="10px"
                color="#BCBBB9"
                className={classes.Withdrowlist}
              >
                Account Holder
              </Typography>
              <Typography
                mt="10px"
                color="#BCBBB9"
                className={classes.Withdrowlist}
              >
                IBAN
              </Typography>{" "}
              <Typography
                mt="10px"
                color="#BCBBB9"
                className={classes.Withdrowlist}
              >
                BIC
              </Typography>
            </Box>
            <Box
              mb="10px"
              display="flex"
              alignItems="end"
              flexDirection="column"
            >
              <Typography mt="16px" className={classes.modal1text}>
               {item?.bankName}
              </Typography>
              <Typography mt="10px" className={classes.Withdrowlist}>
                {item?.bankHolder}
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography
                  color="#FBBF04"
                  mt="10px"
                  className={classes.modal1text}
                >
                  {item?.bankIban}
                </Typography>
                <Box ml="5px">
                  <img src={copyicon} alt="" />
                </Box>
              </Box>
              <Typography mt="10px" className={classes.Withdrowlist}>
                {item?.bankBic}
              </Typography>
            </Box>
          </Box>
          <Box
            borderTop="1px solid #252525"
            sx={{
              background: "#252525",
              mt: "36px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "20px",
              borderRadius: "8px",
            }}
          >
            <Box width="244px">
              <Typography mt="16px" className={classes.modal1text}>
                Need help
              </Typography>
              <Typography color="#717171" className={classes.Withdrowlist}>
                If there is a problem with the transactions make sure to contact
                your support.
              </Typography>
            </Box>
            <Box mt="15px">
              <Button
                width="85px"
                textTransform="capitalize"
                label="Contact"
                height="41px"
                color={"#FBBF04"}
                onClick={handleOpen}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
