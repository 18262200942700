import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Header, LeftBar } from "..";

const Layout = ({ children }) => {
  const [mobile, setMobile] = useState();
  useEffect(() => {
    window.addEventListener("resize", () => {
      setMobile(window.innerWidth);
    });
  });

  useEffect(() => {
    setMobile(window.innerWidth);
  }, []);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <LeftBar mobile={mobile} />
      <Box 
        sx={{ width: "100%" }} >
        <Box
        sx={{ width: "100%" }} 
          backgroundColor={{ md: "#1B1B1B", xs: "" }}
          p="0px 30px 17px 20px"
        >
          <Header />
        </Box>
        <Box
          sx={{
            paddingX: { md: "30px", xs: "10px" },
            height: "100vh",
            overflowY: "auto",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
