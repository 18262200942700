import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "../../styles";
import CircularProgress from '@mui/material/CircularProgress';
const Index = ({ label, value, img, ratioval, ratio , isFetching}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} lg={4}>
      <Box className={classes.staticCard}>
        {
          !isFetching?(
            <Box>
              <Typography className={classes.total}>{value}</Typography>
              <Typography className={classes.totaP}>{label}</Typography>

              <Typography className={classes.ratioval}>
                <span style={{ marginRight: "3px", color: "#55BA68" }}>{ratio}</span>
                {ratioval}
              </Typography>
            </Box>
          ):(
            <CircularProgress size={70}/>
          )
        }
        <Box mt="40px">
          <img src={img} />
        </Box>
      </Box>
    </Grid>
  );
};

export default Index;
