import { Grid, Typography } from "@mui/material";
import React from "react";
import { Layout, Button, Account } from "../../components";
import { user } from "../../imageSource";
import { Box } from "@mui/system";
import { useStyles } from "../../styles";

const Index = () => {
  const classes = useStyles();
  
  return (
    <Layout>
      <Grid container mb="220px">
        <Grid md={12} xs={12}>
          <Typography className={classes.maintext}>Settings</Typography>
        </Grid>
        <Grid
          item
          md={2.5}
          xs={12}
          sx={{
            mt: { md: "59px", xs: "16px" },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box width={{ md: "232px", xs: "360px" }} mr="10px">
            <Button
              textTransform="capitalize"
              label="Account"
              border="none"
              fontSize="14px"
              height="57.44px"
              bg="#1B1B1B"
              color={"#FBBF04"}
              startIcon={<img src={user} alt={"images"} />}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={9.5}>
          <Account />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Index;
