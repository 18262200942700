import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useBankTransfer, useCrypto, usePayPal } from "../hooks/withdrow";
import  {isAddress, isError} from "ethers"
export const WithdrowContext = createContext();

export function useWithdrow(){
    return useContext(WithdrowContext);
}

export default  function WithdrowProvider({children}){
    const [kycIsOpen,setKycIsOpen] = useState(false);
    const [withdrowIsOpen,setWithdrowIsOpen] = useState(false);
    const [payWay,setPayWay] = useState(-1); //0:paypal,1:bank transfer 2:busd
    const [amount,setAmount] = useState(1);
    const [paypalEmail,setPaypalemail] = useState("");
    const [bankName,setBankName] = useState("");
    const [holder,setHolder] = useState("");
    const [iban,setIBan] = useState("");
    const [bic,setBIC] = useState("");
    const [crypto_address,setCryptoAddress] = useState("");
    const [trId,setTrId] = useState("");
    const [smsCode,setSmsCode] = useState("");
    const paypalHandler= usePayPal();
    const bankHandler= useBankTransfer();
    const cryptoHandler = useCrypto();
    
    const {isLoading:isCryptoLoading,data:CryptoData,isError:isCryptoError,error:CryptoError} = cryptoHandler
    const {isLoading:isPaypalLoading,data:PaypalData,isError:isPaypalError,error:PaypalError}=paypalHandler;
    const {isLoading:isBankLoading,data:BankData,isError:isBankError,error:BankError} =bankHandler;
   
    
    const cryptoData = useMemo(()=>{
        if(payWay === 2 ){   
            if(!amount){
                return null;
            }
            if(!isAddress(crypto_address)){
                return null;
            }
            return {amount,crypto_address};
        }else return null;
    },[payWay,amount,crypto_address])
    
    const paypalData = useMemo(()=>{
        if(payWay === 0 ){   
            if(!amount){
                return null;
            }
            if(paypalEmail===""){
                return null;
            }
            return {amount,paypal_email:paypalEmail};
        }else return null;
    },[payWay,amount,paypalEmail])
    
    const bankData = useMemo(()=>{
        if(payWay===1 ){
            if(!amount){
                return null;
            }
            if(bankName==="" || holder === "" || iban === ""  || bic === ""){
                return null;
            }
            return {amount,bank_name:bankName,holder:holder,bic,iban}
        }return null;
    },[payWay,amount,bankName,holder,iban,bic])
    console.log("bankdata:",bankData);
    console.log("PaypalData:",paypalData);
    console.log("CryptoData:",cryptoData);
    const handleWithdrow = useCallback(()=>{    
        if(paypalHandler && cryptoHandler && bankHandler){
            if(payWay===0 && paypalData){
                paypalHandler.mutate(paypalData);
                return;
            }
            if(payWay ===1 && bankData){
                bankHandler.mutate(bankData)
                return;
            }
            if(payWay ===2 && cryptoData){
                cryptoHandler.mutate(cryptoData);
                return;
            }
            toast.warn("Please input all information correctly");
            
        }
    },[paypalHandler,paypalHandler, cryptoHandler ,payWay,bankData,cryptoData,paypalData]);
   
    const isLoading = useMemo(()=>{
        if(isCryptoLoading && payWay ===2 || isBankLoading && payWay ===1 ||isPaypalLoading && payWay ===0) return true;
        else return false;
    },[isCryptoLoading,isBankLoading,isPaypalLoading])
    const Data = useMemo(()=>{
        if(payWay === 0 && PaypalData){
            return PaypalData;
        }
        if(payWay === 1 && BankData){
            return BankData;
        }
        if(payWay === 2 && CryptoData){
            return CryptoData;
        }
        return undefined
    },[payWay,BankData,CryptoData,PaypalData])
    const IsError = useMemo(()=>{
        if(payWay === 0){
            return isPaypalError;
        }
        if(payWay === 1){
            return isBankError;
        }

        if(payWay === 2) {
            return isCryptoError;
        } 
        return undefined
    },[payWay,isPaypalError,isBankError,isCryptoError])
    const Error = useMemo(()=>{
        if(payWay === 0 && PaypalError){
            return PaypalError;
        }
        if(payWay === 1 && BankError){
            return BankError;
        }
        if(payWay === 2 && CryptoError){
            return CryptoError;
        }
        return undefined
    },[payWay,BankError,CryptoError,PaypalError])
    
    const value={
        Data,
        IsError,
        Error,
        payWay,
        handleWithdrow,
        setPayWay,
        amount,
        setAmount,
        paypalEmail,
        setPaypalemail,
        bankName,
        setBankName,
        holder,
        setHolder,
        kycIsOpen,
        iban,
        setIBan,
        bic,
        setBIC,
        crypto_address,
        setCryptoAddress,
        setKycIsOpen,
        withdrowIsOpen,
        setWithdrowIsOpen,
        isLoading,
     
        trId,
        setTrId,
        smsCode,
        setSmsCode
    }
    return (
        <WithdrowContext.Provider value={value}>
            {children}
        </WithdrowContext.Provider>
    )
}