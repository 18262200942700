import * as React from "react";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useStyles } from "../../styles";
import {  Modal1 } from "..";
import { useBalance } from "../../hooks/withdrow";
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';
const Index = () => {
  const classes = useStyles();
  const {isFetching,data,isError,error} = useBalance();
  if(isError || !data?.status){
    toast.error(isError?error?.message:data?.msg);
  }
  const Balance = React.useMemo(()=>{
    if(!isError && data?.status) {
      return data?.data;
    }return 0
  },[isError,data])
  return (
    <Box className={classes.balancecard}>
      {!isFetching?<Typography mt="44px" className={classes.amount}>${Balance} USD</Typography>:<CircularProgress />}
      <Typography mt="8px" className={classes.available}>Available</Typography>
      <Modal1 />
    </Box>
  );
};

export default Index;
