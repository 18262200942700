import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Component } from "react";
import { circleimg } from "../../imageSource";
import { useStyles } from "../../styles";

const classes = useStyles;
class Donut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {},
      series: [10, 55, 55, 17, 15],
      labels: ["A", "B", "C", "D", "E"],
    };
  }

  render() {
    return (
      <Grid
        item
        md={5.5}
        style={{
          backgroundColor: "#1B1B1B",
          height: "auto",
          borderRadius: "8px",
          padding: "20px",
        }}
        className="donut"
      >
        <Typography
          sx={{
            fontWeight: "500 !important",
            fontFamily: "Inter !important",
            fontSize: "14px !important",
            lineHeight: "21px!important",
          }}
        >
          From Affiliator
        </Typography>
        <Box mt="17px" display="flex" alignItems="center">
          <Box>
            <img src={circleimg} alt="" />
          </Box>
          <Box display="flex">
            <Box
              sx={{
                width: "10px",
                height: "10px",
                borderRadius: "100px",
                background: "#FBBF04",
                m: "5px 10px 10px 10px",
              }}
            ></Box>
            <Typography className={classes.headeruser}>
              Flash Fransfer
            </Typography>
          </Box>
        </Box>
        {/* <Chart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width="280"
        /> */}
      </Grid>
    );
  }
}

export default Donut;
