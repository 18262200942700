import { useQuery } from "@tanstack/react-query";
import { getRevenue, getTopAffiliators, getTopRevenu, getTotalPoint } from "../api/query-client";

export const useTotalPoint = ()=>{
    const queryKey = ["getTotalPoint"];
    return useQuery(queryKey,async function(){
        const {data} = await getTotalPoint();
        return data;   
    })
}
export const useTopAffiliators = ()=>{
    const queryKey = ["topAffiliators"];
    return useQuery(queryKey,async function(){
        const {data} = await getTopAffiliators();
        return data;
    })
}
export const useRevenue=(method="month",page=0)=>{
    const queryKey = ["getRevenue",method,page];
    return useQuery(queryKey,async function(){
        const {data} = await getRevenue({method});
        return data;
    })
}
export const useTopRevenue = (method="month")=>{
    const queryKey = ["getTopRevenue",method];
    return useQuery(queryKey,async function(){
        const {data} = await getTopRevenu();
        return data;
    })
}