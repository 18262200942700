import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo ,useState} from "react";
import { toast } from "react-toastify";

import people from "../../assets/images/peopleimg.svg";
import {
  Layout,
  LeaderCard,
  LineChart,
  CircleChart,
  Ranking,
  EvolutionPoints,
  Button,
} from "../../components";
import { useRanking } from "../../hooks/leaderborad";
import { useReward } from "../../hooks/overview";
import { useRevenue, useTopAffiliators, useTopRevenue } from "../../hooks/statistics";
import { blackp, down, rank as _rank, up, whitep } from "../../imageSource";
import { useStyles } from "../../styles";
import CircularProgress from '@mui/material/CircularProgress';
const Index = () => {
  const [method,setMethod] = useState("month") 
  const [methodEP,setMethodEP] = useState("month");
  const classes = useStyles();
  const {isFetching,data,isError,Error} = useRanking();
  const {isFetching:rewardFetching,data:rewardData,isError:rewardIsError,error:rewardError} = useReward();
  const {isFetching:isRevFetching,isRefetching:isRevRefetching,data:revData,isError:isRevError,Error:revError} = useRevenue(method,1);
  const {isFetching:isEPFetching,data:EPData,isError:isEPError,Error:EPError} = useRevenue(methodEP,3);
  const {isFetching:isEPTFetching,data:EPTData,isError:isEPTError,Error:EPTError} = useTopRevenue(methodEP);
  
  const {isFetching:topIsFetching,data:topData,isError:topIsError,Error:topError} = useTopAffiliators();
  
 
  
  if(rewardIsError || !rewardData?.status){toast.error(rewardIsError?rewardError?.message:rewardData?.msg);}
  if(isEPError || !EPData?.status){toast.error(isEPError?EPError?.message:EPData?.msg);}
  if(isEPTError || !EPTData?.status){toast.error(isEPTError?EPTError?.message:EPTData?.msg);}
  if(topIsError) toast.error(topError?.message);
  if(!topData?.status) {toast.error(topData?.msg);}
  if(isError )toast.error(Error?.message)
  if(!data?.status){toast.error(data?.msg)}
  
  const _isEPFetching = isEPFetching || isEPTFetching;
  const RevData  = useMemo(()=>{
    if(!isRevError && revData?.status){
        return revData?.data;
    }else{
        return []
    }
  },[isError,revData,method])
  
  const RewardData = useMemo(()=>{
    if(rewardData?.status){
      return rewardData.rankInfo;
    }else return null;
 },[rewardData,rewardIsError])
 const _EPData =  useMemo(()=>{
  if(!isEPError && EPData?.status){
    return EPData?.data;
  }return []
 },[isEPError,EPData])

 const _EPTData =  useMemo(()=>{
  if(!isEPTError && EPTData?.status){
    return EPTData?.data;
  }return []
 },[isEPTError,EPTData])


 const TopData = useMemo(()=>{
  if(topIsError) return [];
  else {
    if(!topData?.status) return [];
    else return topData.data
  }
},[topData,topIsError])

const tops = TopData.map((item,index)=>{
  const image = item?.profileImg?item.profileImg:people;
 
  const name = item?.userName?item?.userName:"";
  const points = item?.totalEarn*100;
  if(index===0){
    return {
      img2: _rank,
      img1:image,
      name:name,
      date:points.toFixed(0)
    }
  }else{
    return {
      img1:image,
      name:name,
      date:points.toFixed(0)
    }
  }
  
})
 const RankData = useMemo(()=>{
  if(data?.status){
    return {rank:data?.rank,record:data?.record}
  }return null
 },[data,isError])

 const rank = useMemo(()=>{
  if(RewardData && RankData){
    return {...RewardData,...RankData}
  }else{
    if(!RewardData && !RankData){
      return null
    }else{
      if(!RewardData) return RankData;
      else return RewardData;
    }
  }
 },[RewardData,RankData])


  return (
    <Layout name="headername">
      <Grid>
        <Typography className={classes.maintext}>Leaderboard</Typography>
      </Grid>
      <Grid container mb="220px">
        <Grid md={8} xs={12} sx={{ marginTop: { md: "57px", xs: "16px" } }}>
          <Grid container>
            <LeaderCard isFetching={isFetching} label={rank?rank.rank?rank.rank:0:0}value="Rank" />
            <LeaderCard
              isFetching={isFetching}
              img={whitep}
              img1={blackp}
              label={`${rank?rank.percent?rank.percent:0:0}%`}
              value="Next rank"
            />{" "}
            <LeaderCard isFetching={isFetching} label={`${rank?rank.curLevel?rank.curLevel:0:0}`}value="Level" />{" "}
            <LeaderCard isFetching={isFetching} label={`${rank?rank.record?rank.record:0:0}`} value="Record" />{" "}
          </Grid>
          <Grid item md={12} mt="20px">
            <EvolutionPoints EPData = {_EPData} EPTData ={_EPTData} isFetching ={_isEPFetching} method={methodEP} setMethod={setMethodEP} />
          </Grid>
          <Grid
            item
            md={12}
            display="flex"
            flexDirection={{ md: "row", xs: "column" }}
          >
            <CircleChart />
            <LineChart isFetching={isRevFetching}  method={method} setMethod={setMethod} data={RevData}/>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            mt: { md: "65px", xs: "24px" },
            borderRadius: "8px",
            background: "#1B1B1B",
            height: "807px",
            p: "20px",
          }}
        >
          <Typography className={classes.transactiontext}>Ranking</Typography>
          <Box p="10px" className={classes.con2} mt="20px">
            <Box>
              <Typography
                color="#FBBF04"
                borderBottom="1px solid #FBBF04"
                className={classes.theader}
              >
                Username
              </Typography>
            </Box>
            <Box>
              <Typography
                ml="-10px"
                color="#717171"
                className={classes.theader}
              >
                Rank
              </Typography>
            </Box>
            <Box>
              <Typography color="#717171" className={classes.theader}>
                Points
              </Typography>
            </Box>
          </Box>
        {
          !topIsFetching?(
            <>
              {tops.map((data) => {
                return (
                  <>
                    <Ranking
                      arrow={data.arrow}
                      img1={data.img1}
                      img2={data.img2}
                      name={data.name}
                      date={data.date}
                    />
                  </>
                );
              })}
            </>
          ):(
            <Box sx={{width:"100%",minHeight:"300px",display:"flex",justifyContent:"center",paddingTop:"50px"}}>
              <CircularProgress  size={100}/>
            </Box>
          )
        }
          <Box sx={{ mt: "42px", display: "flex", justifyContent: "center" }}>
            <Button
              width="199px"
              textTransform="capitalize"
              label="Show More"
              height="41px"
              color={"#FBBF04"}
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Index;
