import { Box } from "@mui/system";
import React from "react";
import { LoginForm } from "../../components";

const Index = () => {
  return (
    <Box>
      <LoginForm />
    </Box>
  );
};

export default Index;
