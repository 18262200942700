import { Grid, Box, Typography } from "@mui/material";
import React,{useMemo} from "react";
import { more, profile } from "../../imageSource";
import { useStyles } from "../../styles";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
  } from "@mui/material/LinearProgress";
import { useReward } from "../../hooks/overview";
import { useAuth } from "../../context/auth_context";
import CircularProgress from '@mui/material/CircularProgress';
const Index = () => {
  const classes = useStyles();
  const {user }= useAuth();
  
  const {isFetching:rewardFetching,data:rewardData,isError:rewardIsError,error:rewardError} = useReward();
  if(rewardIsError || !rewardData?.status){
    toast.error(rewardIsError?rewardError.message:rewardData?.msg);
  }
  const RewardData = useMemo(()=>{
    if(rewardData?.status){
      return rewardData.rankInfo;
    }else return null;
 },[rewardData])
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 20,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "#D4883A1A" ? 900 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 20,
      backgroundColor: theme.palette.mode === "light" ? "#FBBF04" : "#D4883A1A",
    },
  }));
  return (
    <Grid item xs={12} lg={4}>
      <Box className={classes.rewards}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.graphtext}>Rewards Points</Typography>
          <Box display={{ md: "none", xs: "flex" }}>
            <img src={more} alt="" />
          </Box>
        </Box>
        {
          !rewardFetching?(
            <Box>
                <Box mt="32px" display="flex" justifyContent="center">
                  <img
                    src={user?.picture?user.profileImg:profile}
                    alt=""
                    style={{
                      border: "4px solid #FBBF04",
                      padding: "2px",
                      borderRadius: "100px",
                      borderTopColor: "#D4883A1A",
                      borderLeftColor: "#D4883A1A",
                      width: "100px",
                      height: "100px",
                    }}
                  />
                </Box>
                <Box className={classes.level}>
                  <Box>
                    <Typography color="#BCBBB9" className={classes.headeruser}>
                      Level
                    </Typography>
                    <Typography className={classes.leveltext}>{RewardData?RewardData.curLevel:0}</Typography>
                  </Box>
                  <Box>
                    <Typography color="#BCBBB9" className={classes.headeruser}>
                      Current Points
                    </Typography>
                    <Typography className={classes.leveltext}>{RewardData?RewardData.totalPoint:0}</Typography>
                  </Box>
                </Box>
                <Box mt="20px">
                  <BorderLinearProgress variant="determinate" value={50} />
                </Box>
                <Box mt="10px" className={classes.level}>
                  <Box>
                    <Typography color="#BCBBB9" className={classes.modal1text}>
                      Next Level
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.leveltext2}>
                    {RewardData?RewardData.totalPoint:0} / <span style={{ color: "#BCBBB9" }}>{RewardData?RewardData.range:0}</span>
                    </Typography>
                  </Box>
                </Box>
            </Box>
          ):(
           <Box sx={{width:"100%",minHeight:"300px",display:"flex",justifyContent:"center",paddingTop:"50px"}}>
             <CircularProgress  size={100}/>
           </Box>
          )
        }
      </Box>
    </Grid>
  );
};

export default Index;
