import { Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { Layout } from "../../components";
import people from "../../assets/images/peopleimg.svg";
import { useStyles } from "../../styles";
import points from "../../assets/images/points.svg";
import CircularProgress from '@mui/material/CircularProgress';
// import Layout from "./components";
import SettingCards from "../../components/SettingCards/index.js";
import { Box } from "@mui/system";
import { useCommission } from "../../hooks/commission";
import { toast } from "react-toastify";

const Index = () => {
  const classes = useStyles();
  const {isFetching,data,isError,Error} = useCommission();
  if(isError || !data?.status) toast.error(isError?Error?.message:data?.msg);
  const Data = useMemo(()=>{
    if(!isError && data?.status){
      return data.data;
    }else return []
  },[isError,data])
  const comData = Data.map((item,index)=>{
    return {
      img2:points,
      img1:people,
      name: (index ===0?0:Data[index-1].rangeMax)+ "-" + item.rangeMax,
      label: "Flash-transfer.com",
      desc: "Website used",
      points: "75",
      date:item.level,
      earned:item.pourcent.toFixed(2)+"%"
    }
  })
  return (
    <Layout>
      <Grid
        container
        spacing={2}
        sx={{
          mb: "220px",
        }}
      >
        <Grid item xs={12} lg={12}>
          <Grid>
            <Typography className={classes.maintext}>Commissions</Typography>
          </Grid>
          <Grid
            sx={{
              borderRadius: "8px",
              pb: {
                md: "0px",
                xs: "10px",
              },
              background: { md: "none", xs: "#1B1B1B" },
            }}
          >
            <Box
              sx={{
                height: "50px",
                flexWrap: "wrap",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                borderBottom: "1px solid #252525",
                margin: { md: "46px 0px", xs: "16px 10px" },
                padding: {
                  md: "16px 100px 16px 30px",
                  xs: "16px 10px 16px 10px",
                },
              }}
            >
              <Box>
                <Typography
                  ml={{ md: "30px", xs: "0px" }}
                  color="#717171"
                  className={classes.theader}
                >
                  Points
                </Typography>
              </Box>
              <Box display={{ md: "none", xs: "flex" }}>
                <Typography
                  color="#717171"
                  pl={{ md: "0px", xs: "60px" }}
                  className={classes.theader}
                >
                  Level
                </Typography>
              </Box>{" "}
              <Box display={{ md: "flex", xs: "none" }}>
                <Typography
                  pl={{ md: "40px", xl: "100px" }}
                  color="#717171"
                  className={classes.theader}
                >
                  Pourcent
                </Typography>
              </Box>{" "}
              <Box>
                <Typography
                  pr={{ md: "0px", xs: "60px" }}
                  color="#717171"
                  className={classes.theader}
                >
                  About
                </Typography>
              </Box>{" "}
              <Box display={{ md: "flex", xs: "none" }}>
                <Typography color="#717171" className={classes.theader}>
                  Level
                </Typography>
              </Box>{" "}
            </Box>

              {
                !isFetching?(
                  <>
                    {comData.map((data) => {
                        return (
                          <>
                            <SettingCards
                              name={data.name}
                              date={data.date}
                              points={data.points}
                              earned={data.earned}
                              img1={data.img1}
                              label={data.label}
                              description={data.desc}
                              pointsimg={data.img2}
                            />
                          </>
                        );
                      })}
                  </>
                ):(
                  <Box sx={{width:"100%",minHeight:"350px",display:"flex",justifyContent:"center",paddingTop:"100px"}}>
                    <CircularProgress  size={100}/>
                  </Box>
                )
              }
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Index;
