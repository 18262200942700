import { Grid, Box, Typography } from "@mui/material";
// import Switch, { SwitchProps } from "@mui/material/Switch";
import React from "react";
import { useStyles } from "../../styles";

const Index = ({
  name,
  date,

  img1,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          borderTop: "1px solid #292929",
          height: "62px",
          mt:"10px",
          background: "#1B1B1B",
          flexWrap: "wrap",
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          flexDirection: "row",
          padding: { md: "16px 25px", xs: "16px 10px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box mr="10px">
            <img src={img1} alt="" width={30} />
          </Box>
          <Typography mt="5px" className={classes.headeruser}>
            {name}
          </Typography>
        </Box>

        <Box>
          <Typography className={classes.headeruser}>{date}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Index;
