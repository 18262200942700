import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {  Button, Modal4 } from "..";
import { close,  userimg } from "../../imageSource";
import { useStyles } from "../../styles";



export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  return (
    <div>
      <Box width={{ md: "442px", xs: "303px" }}>
        <Button
          textTransform="capitalize"
          label="Continue"
          height="41px"
          border="1px solid #FBBF04"
          color={"#FBBF04"}
          onClick={handleOpen}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        hideBackdrop={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            p: "25px",
            transform: "translate(-50%, -50%)",
            background: "#1B1B1B",
            borderRadius: "8px",
            flexDirection: "column",
            display: "flex",
            width: { md: "492px", xs: "335px" },
            height: "535px",
          }}
        >
          <Box
            sx={{
              background: "#1B1B1B",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          ></Box>
          <Box pb="10px" borderBottom="1px solid #252525" display="flex" justifyContent="space-between">
        

            <Typography  className={classes.transactiontext}>
              Withdraw Money
            </Typography>
            <img src={close} alt="close" onClick={handleClose} />
          </Box>
          <Typography mt="22px" className={classes.headeruser}>
            To
          </Typography>
          <Box
            mt={{ md: "12px", xs: "3px" }}
            p="10px"
            borderRadius="8px"
            border="1px solid #252525"
          >
            <Box display="flex">
              <Box mr="12px">
                <img src={userimg} alt=""/>
              </Box>
              <Box>
                <Typography className={classes.modal1text}>
                  John Smith
                </Typography>
                <Typography color="#717171" className={classes.Withdrowlist}>
                  johnsmith@gmail.com
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography mt="26px" className={classes.modal1text}>
            Amount
          </Typography>
          <Typography color="#717171" className={classes.Withdrowlist}>
            How much would you like to withdraw?
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography mt="32px" className={classes.modal3text}>
              $0.00
            </Typography>

            <Typography className={classes.Withdrowlist}>
              Available Balance:{" "}
              <span style={{ color: "#717171" }}> $7200.50</span>
            </Typography>
          </Box>
          <Box mt="24px">
            {/* <Button
              width="442px"
              textTransform="capitalize"
              label="Withdraw"
              border="none"
              height="41px"
              bg="#FBBF04"
              color={"#151103"}
              onClick={handleOpen}
            /> */}
            <Modal4 />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
