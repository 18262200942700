import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { Drawer, Notification } from "..";
import { useAuth } from "../../context/auth_context";
import { search, site, copyicon, flashmb } from "../../imageSource";
import { useStyles } from "../../styles";
import AppInputFields from "../inpuFeild";

const Index = () => {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <Grid container>
      <Grid
        xs={12}
        sx={{
          display: { md: "none", xs: "flex" },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <Box display="flex">
            <Box mr="5px">
              <img src={flashmb} alt="" />
            </Box>
            <Typography className={classes.mbtitle}>Flash Affiliate</Typography>
          </Box>
        </Link>
        <Box
          sx={{
            display: { md: "none", xs: "flex" },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Notification />
          <Drawer />
        </Box>
      </Grid>

      <Grid
        display={{ md: "flex", xs: "none" }}
        container
        md={12}
        justifyContent="space-between"
      >
        <Grid item md={6}>
          <AppInputFields
            type="text"
            width="294px"
            margin={"10px 0px 0px 0px"}
            borderRadius={"10px"}
            height="57.44px"
            color="white"
            placeholder="Search"
            backgroundColor="#161616"
            inputIcon={<img src={search} alt="" />}
          />
        </Grid>
        <Grid
          item
          md={3.5}
          xl={2.5}
          display="flex"
          justifyContent="end"
          alignItems="center"
        >
          <Box
            mr="20px"
            display="flex"
            justifyContent="end"
            alignItems="center"
          >
            <Notification />
          </Box>
          <Grid
            item
            md={12}
            display="flex"
            justifyContent="end"
            alignItems="center"
          >
            <Box
              sx={{
                height: "41px",
                backgroundColor: "#252525",
                borderRadius: "8px",
                width: "279px",
                p: "13px 18px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              borderRadius={"10px"}
              color="white"
              placeholder={
                "https://flash-transfer-web3.netlify.app/signup/" +
                user?.affiliatorCode
                  ? user?.affiliatorCode
                  : "0"
              }
              backgroundColor="#161616"
            >
              <Box mr="10px">
                <img src={site} alt="" />
              </Box>
              <Typography
                mt="5px"
                className={classes.headeruser}
                color="#717171"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}
              >
                <a
                  style={{
                    textDecoration: "none",
                    color: "#717171",
                    // background:"red"
                  }}
                  target={"_blank"}
                  href={
                    "https://flash-transfer-web3.netlify.app/signup/" +
                    (user?.affiliatorCode ? user?.affiliatorCode : "0")
                  }
                >
                  {"https://flash-transfer-web3.netlify.app/signup/" +
                    (user?.affiliatorCode ? user?.affiliatorCode : "0")}
                </a>
              </Typography>
              <Box ml="10px">
                <img src={copyicon} alt="" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Index;
