import { Grid, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import {
  OverviewAmount,
  Layout,
  Stacking,
  Rewards,
  LastTransaction,
} from "../../components";
import people from "../../assets/images/peopleimg.svg";
import { useStyles } from "../../styles";
import points from "../../assets/images/points.svg";
import { a1, a2, a3, a4 } from "../../imageSource";
import { Box } from "@mui/system";
import {  useLastTransaction, useTotalEarn } from "../../hooks/overview";
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';
import { useRevenue } from "../../hooks/statistics";

const Index = () => {
  const classes = useStyles();
  const [method,setMethod] = useState("month") 
  const {isFetching,data:earnData,isError,error} = useTotalEarn();
  const {isFetching:trIsFetching,data:trData,isError:trIsError,error:trError} = useLastTransaction();
  const {isFetching:isRevFetching,isRefetching:isRevRefetching,data:revData,isError:isRevError,error:revError} = useRevenue(method,2);
  if(isRevError || !earnData?.status){
    toast.error(isError?revError?.message:earnData?.msg);
  }
  if(isError || !earnData?.status){
    toast.error(isError?error?.message:earnData?.msg);
  }
  if(trIsError || !trData?.status){
    toast.error(trIsError?trError?.message:trData?.msg);
  }

  const RevData  = useMemo(()=>{
    if(!isRevError && revData?.status){
        return revData?.data;
    }else{
        return []
    }
  },[isError,revData,method])
 const EarnData = useMemo(()=>{
    if(earnData?.status && !isError){
      return earnData.data;
    }else{
      return null;
    }
 },[earnData,isError])

 
const TrData = useMemo(()=>{
  if(trData?.status && !trIsError){
     return trData.lstTransactions;
  }else return null;
},[trData,trIsError]) ;
const lstTrans = useMemo(()=>{
  if(TrData){
    if(TrData.length>=3){
      return TrData.slice(0,3);
    }else{
      return TrData;
    }
  }else return [];
},[TrData])

const transactions = lstTrans.map((item)=>{

  return {
    img2:points,
    img1:item.profileImg,
    name: "Username",
    namdec:item.firstName+" "+item.lastName,
    desc:item.createdAt,
    points:item.amount*100,
    earned: "Earned",
    date:"$"+item.amount
  }
})
console.log("lstTransaction:",transactions);
  const transaction = [
    {
      img2: points,
      img1: people,
      name: "Username",
      label: "Transaction date",
      desc: "Feb 6, 2021 3:18 am",
      points: "75",
      date: "$0,75",
      earned: "Earned",
      namdec: "Michael Scotti",
    },
    {
      img2: points,
      img1: people,
      name: "Username",
      label: "Transaction date",
      desc: "Feb 6, 2021 3:18 am",
      points: "75",
      date: "$0,75",
      earned: "Earned",
      namdec: "Michael Scotti",
    },
    {
      img2: points,
      img1: people,
      name: "Username",
      label: "Transaction date",
      desc: "Feb 6, 2021 3:18 am",
      points: "75",
      date: "$0,75",
      earned: "Earned",
      namdec: "Michael Scotti",
    },
  ];
  return (
    <Layout name="headername">
      <Grid>
        <Typography className={classes.maintext}>Overview</Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          paddingTop: { md: "67px", xs: "16px" },
        }}
      >
        <OverviewAmount img={a1} label={`$${EarnData?EarnData.week.toFixed(2):0}`} isFetching={isFetching} value="Last Week" />
        <OverviewAmount img={a2} label={`$${EarnData?EarnData.month.toFixed(2):0}`} isFetching={isFetching} value="Last Month" />{" "}
        <OverviewAmount img={a3} label={`$${EarnData?EarnData.half.toFixed(2):0}`} isFetching={isFetching} value="Half year" />{" "}
        <OverviewAmount img={a4} label={`$${EarnData?EarnData.all.toFixed(2):0}`} isFetching={isFetching} value="All time" />
      </Grid>
      <Grid container sx={{ marginTop: { md: "10px", xs: "10px" } }}>
        <Stacking isFetching={isRevFetching}  method={method} setMethod={setMethod} data={RevData} />
        <Rewards  />
      </Grid>
      <Grid
        container
        sx={{
          marginTop: "10px",
          padding: "16px 10px",
          background: "#1B1B1B",
          borderRadius: "8px",
          marginBottom: "160px",
          gap: { sm: "17px", lg: "0px" },

        }}
      >
        <Grid md={12} xs={12}>
          <Box justifyContent="space-between" alignItems="center" display="flex">
            <Typography className={classes.graphtext}>
              Last Transactions
            </Typography>
            <Typography className={classes.view}>
            View all
            </Typography>
         
          </Box>
        </Grid>
          {
            !trIsFetching?(
              <>
                       {transactions.map((data) => {
                          return (
                            <>
                              <LastTransaction
                                namec={data.namdec}
                                name={data.name}
                                date={data.date}
                                points={data.points}
                                earned={data.earned}
                                img1={data.img1}
                                label={data.label}
                                description={data.desc}
                                pointsimg={data.img2}
                              />
                            </>
                          );
                        })}
              </>
            ):(
              <Box sx={{width:"100%",minHeight:"300px",display:"flex",justifyContent:"center",paddingTop:"50px"}}>
                <CircularProgress  size={100}/>
              </Box>
            )
          }
      </Grid>
    </Layout>
  );
};

export default Index;
