import { Grid, Box, Typography } from "@mui/material";
// import Switch, { SwitchProps } from "@mui/material/Switch";
import React from "react";
import { useStyles } from "../../styles";

const Index = ({
  name,
  pointsimg,
  points,
  date,
  earned,
  img1,
  label,
  description,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} lg={12}>
      <Box
        sx={{
          color: "white",
          background: "#1B1B1B",
          borderRadius: "8px",
          flexWrap: "wrap",
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          alignItems: "center",
          flexDirection: "row",
          padding: { md: "16px 25px", xs: "16px 10px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "15%",
          }}
        >
          <Box mr="8px">
            <img src={img1} width={50} alt="" />
          </Box>
          <Typography className={classes.headeruser}>{name}</Typography>
        </Box>

        <Box
      
          ml={{

        
            md: "-20px",
            xs: "-20px",
          }}
        >
          <Typography className={classes.headeruser}>{earned}</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          ml={{ md: "", xs: "-70px" }}
          mr={{ md: "", xs: "-50px" }}
          flexDirection="column"
        >
          <Typography
            color="#BCBBB9"
            className={(classes.headeruser, classes.elipstext)}
          >
            {label}
          </Typography>
          <Typography ml="10px" color="#717171" className={classes.headeruser}>
            {description}
          </Typography>
        </Box>
        <Box sx={{ display: { md: "flex", xs: "none" } }}>
          <Box>
            <img src={pointsimg} alt="" />
          </Box>
          <Typography mt="15px" className={classes.uname}>
            {points.toFixed(0)}
          </Typography>
        </Box>

        <Box display={{ md: "flex", xs: "none" }} mt="5px">
          <Typography className={classes.modal1text}>{date}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Index;
