import React, { useMemo } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useStyles } from "../../styles";
import Chart from "react-apexcharts";
const Staking = ({isFetching,method,setMethod,data}) => {
  const classes = useStyles();
  
  const calculatePercent = (_data)=>{
    var sum=0;
    var count =0;
   
    const result=  _data.map((item,index)=>{
      let _item;
      if(typeof item ==="string") _item= parseFloat(item);
      else _item = item;
      if(index===0) { sum+=parseFloat(_item); count++; return 0; }
      else{
        const av = sum/count;
        const p = av===0?100:(100*_item/av).toFixed(1);
        sum+=_item; count++;
        return p;
      }
    })
    console.log("result",result)
    return result;
  }
  const makeLabels=(method)=>{
    if(method === "month"){
      const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","De"];
      let result=[]
      for(let i=0;i<12;i++){
        result[i] = `${i+1} ${month[i]}`
      }
      
      return result;
    }
    return []
  }
  const series = useMemo(()=>{
    if(method==="month"){
      return [
        {
          data: calculatePercent(data),
        },
      ];
    }else{
      
      var labels=[] ;var datas=[];
      for (const [key, value] of Object.entries(data)) {
        labels.push(key);
       if(typeof data === "string") datas.push(parseFloat(value));
       else datas.push(value)
     }
      return [
        {
          data: calculatePercent(datas),
        },
      ];
    }
       
  },[data,method]);
  console.log("serie",series)
  const categories = useMemo(()=>{
    if(method === "month"){
      return makeLabels(method);
    }else{
      var labels =[];
      for (const [key, value] of Object.entries(data)) {
        labels.push(key);
     }
     return labels;
    }
  },[data,method])

  const options = useMemo(()=>{
    return  {
      chart: {
        toolbar: { show: false },
      },
      stroke: {
        curve: "smooth",
        colors: ["#FBBF04", "#FBBF04", "#FBBF04"],
      },
      markers: {
        size: 0,
        sizeOffset: 12,
        colors: "#FBBF04",
        strokeColors: "#FBBF04",
        strokeWidth: 12,
        strokeOpacity: 0.2,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        showNullDataPoints: true,
        hover: {
          size: 7,
        },
      },
  
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          return (
            '<div class="chart-tooltip-container">' +
            '<span class="chart-tooltip-font-date">' +
            w.globals.categoryLabels[dataPointIndex] +
            "</span><br/>" +
            '<span class="chart-tooltip-font" >' +
            series[seriesIndex][dataPointIndex] +
            " SVENT </span>" +
            "</div>"
          );
        },
      },
  
      xaxis: {
        categories:categories,
        labels: {
          style: {
            colors: "white",
            fontSize: "12px",
          },
        },
        tooltip: false,
      },
      yaxis: {
        
        labels: {
          style: {
            colors: "white",
          },
        },
      },
    };
  },[series,categories])
  
  return (
    <Grid
      item
      md={7.8}
      xs={12}
      m={{ md: "8px 10px 10px 5px", xs: "5px 0px 5px 2px" }}
      backgroundColor="#1B1B1B"
      borderRadius="8px"
    >
      <div className={classes.ps_container}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: { md: "10px 20px", xs: "10px 10px 0px 10px" },
            flexDirection: "row",
          }}
        >
          <Box>
            <Typography className={classes.graphtext}>
              Affiliate Income Progression
            </Typography>
          </Box>

          <Box>
            <Select
              value={method}
              onChange={(e)=>setMethod(e.target.value)}
              disableUnderline
              variant="standard"
              sx={{
                height: "28px",
                fontSize: "12px",
                fontWight: 600,
                borderRadius: "5px",
                color: "#BCBBB9",
                p: "0px 10px",
                width: "85px",
                border: "1px solid #252525",
                ".MuiSelect-icon": {
                  color: "white",
                },
              }}
            >
              <MenuItem value="week">week</MenuItem>
              <MenuItem value="month">month</MenuItem>
              <MenuItem value="year">year</MenuItem>
              
            </Select>
          </Box>
        </Box>

        <Box sx={{ marginTop: "0px" }}>
          <Chart
            options={options}
            series={series}
            type="line"
            width="100%"
            height="280px"
          />
        </Box>
      </div>
      <br className="mo-show-only" />
    </Grid>
  );
};

export default Staking;
