import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { search } from "../../imageSource";
import { useStyles } from "../../styles";
import { Layout, Accordion, NeedHelp } from "../../components";
import AppInputFields from "../../components/inpuFeild";

const Index = () => {
  const classes = useStyles();

  return (
    <Layout name="headername">
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography className={classes.maintext}>Help</Typography>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            marginTop: { md: "67px", xs: "16px" },
            display: "flex",
            justifyContent: "center",
            background: "#1B1B1B",
            padding: "20px 20px",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography className={classes.faqs}>
              Search for a question
            </Typography>

            <Typography className={classes.typeof}>
              Type your question or search keyword
            </Typography>
          </Box>

          <Grid item md={12}>
            <AppInputFields
              type="text"
              margin={"12.62px 0px 0px 0px"}
              // width="563.29px"
              borderRadius={"10px"}
              height="57.44px"
              color="white"
              placeholder="Search"
              backgroundColor="#161616"
              inputIcon={<img src={search} alt={"images"} />}
            />
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: "10px", mb: "220px" }}>
          <Grid item md={8} xs={12}>
            <Accordion />
          </Grid>
          <Grid item md={4} xs={12}>
            <NeedHelp />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Index;
