import * as React from "react";

import Typography from "@mui/material/Typography";
import { date, p1, p2, p3, search } from "../../imageSource";
import { Box } from "@mui/system";
import { useStyles } from "../../styles";
import { ModalBank, ModalBankTransfer, ModalCrypto } from "..";
import AppInputFields from "../inpuFeild";
import { Grid } from "@mui/material";
import { useWithdrowHistory } from "../../hooks/withdrow";
import CircularProgress from '@mui/material/CircularProgress';
import { getBorder, getImg, getStatus, getWithdrowColor } from "../../pages/wallet/constant";
const Index = () => {
  const classes = useStyles();
  const {isFetching:isLoading,isError,data:hisData} = useWithdrowHistory();
  
  const Data = React.useMemo(()=>{
    if(hisData?.status){
      return hisData?.data.map((item)=>{

        return {...item,...{
          img:getImg(item.type),
          cname:item.network,
          name:item.type,
          date:item.updatedAt,
          border:getBorder(item.status),
          btn:getStatus(item.status),
          color:getWithdrowColor(item.status)
      }}
      })
    }else return []
  },[isError,hisData]);
  
  
  return (
  <>
   
        <Box className={classes.transaction} width="100%">
          <Box className={classes.con11}>
            <Typography className={classes.transactiontext}>Transaction</Typography>
          </Box>
          <Grid container md={12} justifyContent="space-between">
            <Grid item md={4} display="flex" alignItems="center">
              <Grid item md={12}>
                <AppInputFields
                  type="text"
                  margin={"12.62px 0px 0px 0px"}
                  borderRadius={"10px"}
                  height="57.44px"
                  color="white"
                  padding="12px 9px"
                  placeholder="8 Mar 2020 - 18 Mar 2020"
                  backgroundColor="#252525"
                  inputIcon={<img src={date} alt={"images"} />}
                />
              </Grid>
              {/* <Grid item md={4}>
                
                <AppInputFields
                  type="text"
                  margin={"12.62px 0px 0px 0px"}
                  // width="563.29px"
                  borderRadius={"10px"}
                  height="57.44px"
                  color="white"
                  placeholder="Search"
                  backgroundColor="#161616"
                  inputIcon={<img src={search} alt={"image"} />}
                />
              </Grid> */}
            </Grid>
            <Grid
              sx={{
                borderRadius: "100px",
                height: "41px",
                display: { md: "none", xs: "flex" },
                alignItems: "center",
                justifyContent: "center",
                width: "41px",
                ml: "10px",
                mt: "20px",
                backgroundColor: "#252525",
              }}
            >
              <img src={search} height="17px" width="17px" alt={"images"} />
            </Grid>
            <Grid item md={4} display={{ md: "flex", xs: "none" }}>
              <AppInputFields
                type="text"
                margin={"12.62px 0px 0px 0px"}
                // width="563.29px"
                borderRadius={"10px"}
                height="57.44px"
                color="white"
                placeholder="Search"
                backgroundColor="#252525"
                inputIcon={<img src={search} alt={"images"} />}
              />
            </Grid>
          </Grid>
          <Box
            className={classes.con2}
            mt={{ md: "20px", xs: "10px" }}
            p={{ md: "20px 20px", xs: "20px 0px" }}
          >
            <Box>
              <Typography color="#717171" className={classes.theader}>
                Type
              </Typography>
            </Box>
            <Box display={{ md: "flex", xs: "none" }}>
              <Typography ml="20px" color="#717171" className={classes.theader}>
                Date
              </Typography>
            </Box>
            <Box>
              <Typography ml="-40px" color="#717171" className={classes.theader}>
                Amount
              </Typography>
            </Box>

            <Box display={{ md: "flex", xs: "none" }}>
              <Typography ml="-80px" color="#717171" className={classes.theader}>
                Network
              </Typography>
            </Box>
            <Box display={{ md: "flex", xs: "none" }}>
              <Typography color="#717171" className={classes.theader}>
                Status
              </Typography>
            </Box>
          </Box>
        {
          !isLoading?(
            <>
              {Data.map((item, index) => {
                  return (
                    <Box
                      className={classes.con2}
                      p="14px 10px"
                      borderTop="1px solid #252525"
                    >
                      <Box display="flex" alignItems="center">
                        <img src={item.img} alt="images" />
                        <Box width="110px">
                          <Typography
                            ml="10px"
                            color="white"
                            className={classes.Withdrowlist}
                          >
                            {item.name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display={{ md: "flex", xs: "none" }}>
                        <Typography color="white" className={classes.Withdrowlist}>
                          {item.date}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography color="white" className={classes.Withdrowlist}>
                          {item.rate}
                        </Typography>
                      </Box>
                      <Box display={{ md: "flex", xs: "none" }}>
                        <Typography
                          width="70px"
                          color="white"
                          className={classes.Withdrowlist}
                        >
                          {item.cname}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: { md: "flex", xs: "none" },
                          mt: "15px",
                          justifyContent: "end",
                        }}
                      >
                        {item.type === "PAYPAL" ? (
                          <ModalBank item={item}/>
                        ) : item.type === "BANK TRANSFER" ? (
                          <ModalBankTransfer item={item} />
                        ) : (
                          <ModalCrypto item={item} />
                        )}
                        {/* <Button
                          label={item.btn}
                          textTransform="none"
                          bg="#141414"
                          color={item.color}
                          border={item.border}
                          height={"41px"}
                          borderRadius="8px"
                          width="133px"
                        /> */}
                      </Box>
                    </Box>
                  );
                })}
          </>
          ):(
            <Box sx={{width:"100%",minHeight:"300px",display:"flex",justifyContent:"center",paddingTop:"50px"}}>
              <CircularProgress  size={100}/>
            </Box>
          )
        }
        </Box>
     
  </>
  );
};

export default Index;
