import { auth ,storage} from "../firebase";
import {  getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useLocalStorateState } from "../hooks";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import {
    signOut,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signInWithEmailAndPassword,
    sendPasswordResetEmail
  } from "firebase/auth";

export function useImgUpload(){
    
    return useMutation((file)=>{
        const tmp = file.name.split(".")
        const ext = tmp[tmp.length-1];
        const curDate = new Date();
        const fileName =curDate.getTime()+"."+ext;
        const storageRef  = ref(storage,"/images/"+fileName);
        return uploadBytes(storageRef,file);
    },{
        onError:(err,value,context)=>{
            toast.error(err?.message);
        },
        onSuccess:async (data,val,context)=>{
            console.log("data",data);
            
        }
    })
}
export function getDownloadUrl (fullPath){
    const storageRef  = ref(storage,fullPath);
    return getDownloadURL(storageRef);
}
export function useSignIn(){
    const navigate = useNavigate();
    const [token,setLocalStorageState] = useLocalStorateState("token","");

    const queryClient = useQueryClient()
    const [pro_email,setProfileEmail ] = useLocalStorateState("pro_email",""); 
    return useMutation(({email,password})=>signInWithEmailAndPassword(auth,email,password),{
        onError:(err,value,context)=>{
            toast.error(err?.message);
            queryClient.invalidateQueries(["getUser"]);
            navigate("/");
        },
        onSuccess:async (data,val,context)=>{
            const token = await auth.currentUser?.getIdToken();
            queryClient.invalidateQueries(["getUser"]);
            setLocalStorageState(token);
            setProfileEmail(auth.currentUser.email);
            navigate("/overview");
        }
    });
}

export function useSignUp(){
    
    return useMutation(({email,password})=>createUserWithEmailAndPassword(auth,email,password),{
        onError:(err,value,context)=>{
            toast.error(err?.message) ;
            return;   
        },
        onSuccess:(userData,val,context)=>{
            const user = userData.user;
            if(!user?.emailVerified && auth.currentUser){
                sendEmailVerification(auth.currentUser).then(()=>{
                    auth.onAuthStateChanged(user=>{
                        if(user?.emailVerified){
                            console.log("user changed:",user);
                        }
                    })
                })
            }
            
        }
    });
}

export function useResetEmail (){
    const navigate=useNavigate();
   return useMutation(email=>sendPasswordResetEmail(auth,email),{
        onError:(err,value,context)=>{
            toast.error(err?.message) ;
            return;   
        },
        onSuccess:(data,val,context)=>{
            toast.success("Please check your Email");
            navigate("/")
        }
    })
}
export  function  logOut(){
    auth.signOut().then( res => {
        //remove the token
        localStorage.removeItem('token')
      })
      .catch(err => {
        //there shouldn't every be an error from firebase but just in case
      toast.error(err?.message);
        //whether firebase does the trick or not i want my user to do there thing.
          localStorage.removeItem('token')
              console.error(err?.message)
      })
  
};
export function useSignOut(){
    const navigate=useNavigate();
    const queryClient = useQueryClient();
    return useMutation(()=>auth.signOut(),{
        onError:(err,value,context)=>{
            toast.error(err?.message);
            queryClient.invalidateQueries(["getUser"]);
        },
        onSuccess:(data,value,context)=>{
            localStorage.removeItem('token')
            navigate("/");
            queryClient.invalidateQueries(["getUser"]);
        }
    })
}