import React, { useMemo } from "react";
import { Box, Grid, MenuItem, Select } from "@mui/material";
import { useStyles } from "../../styles";
import "./index.css";

import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
// const Staking = ({EPData ,EPTData ,isFetching , method, setMethod}) => {
//   const classes = useStyles();

//   const makeLabels=(method)=>{
//     if(method === "month"){
//       const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","De"];
//       let result=[]
//       for(let i=0;i<12;i++){
//         result[i] = `${i+1} ${month[i]}`
//       }

//       return result;
//     }
//     return []
//   }

//   const calculatePercent = (_data)=>{
//     var sum=0;
//     var count =0;

//     const result=  _data.map((item,index)=>{
//       let _item;
//       if(typeof item ==="string") _item= parseFloat(item);
//       else _item = item;
//       if(index===0) { sum+=parseFloat(_item); count++; return 0; }
//       else{
//         const av = sum/count;
//         const p = av===0?100:(100*_item/av).toFixed(1);
//         sum+=_item; count++;
//         return p;
//       }
//     })
//     console.log("result",result)
//     return result;
//   }

//   const seriesEP = useMemo(()=>{
//     if(method==="month"){
//       return [
//         {
//           data: calculatePercent(EPData),
//         },
//       ];
//     }else{

//       var labels=[] ;var datas=[];
//       for (const [key, value] of Object.entries(EPData)) {
//         labels.push(key);
//        if(typeof data === "string") datas.push(parseFloat(value));
//        else datas.push(value)
//      }
//       return [
//         {
//           data: calculatePercent(datas),
//         },
//       ];
//     }

//   },[EPData,method]);

//   const categoriesEP = useMemo(()=>{
//     if(method === "month"){
//       return makeLabels(method);
//     }else{
//       var labels =[];
//       for (const [key, value] of Object.entries(EPData)) {
//         labels.push(key);
//      }
//      return labels;
//     }
//   },[EPData,method])

//   const options = useMemo(()=>{
//     return  {
//       chart: {
//         toolbar: { show: false },
//       },

//       stroke: {
//         curve: "smooth",

//         colors: ["#FBBF04", "#FBBF04", "#FBBF04"],
//       },
//       markers: {
//         size: 0,
//         sizeOffset: 12,
//         colors: "#FBBF04",
//         strokeColors: "#FBBF04",
//         strokeWidth: 12,
//         strokeOpacity: 0.2,
//         fillOpacity: 1,
//         discrete: [],
//         shape: "circle",
//         radius: 2,
//         offsetX: 0,
//         offsetY: 0,
//         showNullDataPoints: true,
//         hover: {
//           size: 7,
//         },
//       },

//       tooltip: {
//         custom: ({ series, seriesIndex, dataPointIndex, w }) => {
//           return (
//             '<div class="chart-tooltip-container">' +
//             '<span class="chart-tooltip-font-date">' +
//             w.globals.categoryLabels[dataPointIndex] +
//             "</span><br/>" +
//             '<span class="chart-tooltip-font" >' +
//             series[seriesIndex][dataPointIndex] +
//             " SVENT </span>" +
//             "</div>"
//           );
//         },
//       },

//       xaxis: {
//         categories:categoriesEP,
//         labels: {
//           style: {
//             colors: "white",
//             fontSize: "12px",
//           },
//         },
//         tooltip: false,
//       },
//       yaxis: {
//         categories: [1, 2, 3, 4, 5, 6, 7, 8],
//         labels: {
//           style: {
//             colors: "white",
//           },
//         },
//       },
//     };
//   },[categoriesEP,seriesEP])

//   return (
//     <Grid
//       item
//       xs={12}
//       sx={{
//         p: { md: "10px 0px", xs: "10px 0px" },
//         m: { md: "-20px 10px 10px 0px", xs: "0px 0px 24px 0px" },
//         background: "#1B1B1B",
//         borderRadius: "3.60215px",
//       }}
//     >
//       <div className={classes.ps_container}>
//         <Box
//           sx={{
//             display: "flex",
//             p: "0px 20px",
//             justifyContent: "space-between",
//             alignItems: "center",
//             flexDirection: "row",
//           }}
//         >
//           <Box>
//             <h4 className="text-white" style={{ fontSize: "20px" }}>
//               Evolution Points
//             </h4>
//           </Box>

//           <Box>
//             <Select
//               value={method}
//               onChange={(e)=>setMethod(e.target.value)}
//               disableUnderline
//               variant="standard"
//               sx={{
//                 height: "28px",
//                 p: "0px 10px",
//                 fontSize: "12px",
//                 fontWight: 600,
//                 borderRadius: "5px",
//                 color: "#BCBBB9",
//                 width: "90px",
//                 border: "1px solid #252525",
//                 ".MuiSelect-icon": {
//                   color: "white",
//                 },
//               }}
//             >
//               <MenuItem value="week">week</MenuItem>
//               <MenuItem value="month">month</MenuItem>
//               <MenuItem value="year">year</MenuItem>

//             </Select>
//           </Box>
//         </Box>

//         <Box sx={{ marginTop: "0px", height: { md: "345px", xs: "200px" } }}>
//           <Chart
//             options={options}
//             series={seriesEP}
//             type="line"
//             width="100%"
//             height="100%"
//           />

//         </Box>
//       </div>
//       <br className="mo-show-only" />
//     </Grid>
//   );
// };
class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "series1",
          data: [31, 40, 28, 51, 42, 109, 100],
        },
        {
          name: "series2",
          data: [11, 32, 45, 32, 34, 52, 41],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          colors: ["#55BA68", "#FBBF04", "#FBBF04"],
        },
        xaxis: {
          type: "datetime",
          categories: [
            "2018-09-19T00:00:00.000Z",
            "2018-09-19T01:30:00.000Z",
            "2018-09-19T02:30:00.000Z",
            "2018-09-19T03:30:00.000Z",
            "2018-09-19T04:30:00.000Z",
            "2018-09-19T05:30:00.000Z",
            "2018-09-19T06:30:00.000Z",
          ],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
    };
  }

  render() {
    // const classes = useStyles();
    return (
      <Grid
        item
        xs={12}
        sx={{
          p: { md: "10px 0px", xs: "10px 0px" },
          m: { md: "-20px 10px 10px 0px", xs: "0px 0px 24px 0px" },
          background: "#1B1B1B",
          borderRadius: "3.60215px",
        }}
      >
        <div
        // className={this.classes.ps_container}
        >
          <Box
            sx={{
              display: "flex",
              p: "0px 20px",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Box>
              <h4 className="text-white" style={{ fontSize: "20px" }}>
                Evolution Points
              </h4>
            </Box>

            <Box>
              <Select
                value={this.props.method}
                onChange={(e) => this.props.setMethod(e.target.value)}
                disableUnderline
                variant="standard"
                sx={{
                  height: "28px",
                  p: "0px 10px",
                  fontSize: "12px",
                  fontWight: 600,
                  borderRadius: "5px",
                  color: "#BCBBB9",
                  width: "90px",
                  border: "1px solid #252525",
                  ".MuiSelect-icon": {
                    color: "white",
                  },
                }}
              >
                <MenuItem value="week">week</MenuItem>
                <MenuItem value="month">month</MenuItem>
                <MenuItem value="year">year</MenuItem>
              </Select>
            </Box>
          </Box>

          <Box sx={{ marginTop: "0px", height: { md: "345px", xs: "200px" } }}>
            {/* <Chart
            options={options}
            series={seriesEP}
            type="line"
            width="100%"
            height="100%"
          /> */}

            <div id="chart">
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="area"
                height={350}
              />
            </div>
          </Box>
        </div>
        <br className="mo-show-only" />
      </Grid>
    );
  }
}

export default ApexChart;
