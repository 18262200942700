import ReactApexChart from "react-apexcharts";
import React from "react";
import {
  Box,
  FormControl,
  Typography,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from "../../styles";
class ApexChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching:props.isFetching,
      method : props.method,
      setMethod : props.setMethod,
      series: [
        {
          // name: "Website Blog",
          type: "column",
          data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
        },
        {
          // name: "Social Media",
          // type: "line",
          // data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
        },
      ],
      options: {
        chart: {
          // height: 350,
          type: "line",
        },
        stroke: {
          width: [0, 1],
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
          //   style:{
          //     color:"red"
          //   }
        },
        labels: [
          
        ],
       
        yaxis: [
          {
            // title: {
            //   text: "Website Blog",
            // },
          },
          {
            opposite: true,
            // title: {
            //   text: "Social Media",
            // },
          },
        ],
      },
    };
  }
  static getDerivedStateFromProps(props, state) {
    state.isFetching = props.isFetching;
   if(props.method==="month")
    return {method: props.method ,series:[{type: "column",data: props.data}], options:{...state.options,labels:ApexChart.makeLabels(props.method)}};
    if(props.method === "week" ||props.method === "year" ){
       let labels = [];let data = [];
      for (const [key, value] of Object.entries(props.data)) {
          labels.push(key);
          data.push(value);
      }
      return {method: props.method ,series:[{type: "column",data: data}], options:{...state.options,labels:labels}};
    }
  }
  static makeLabels=(method)=>{
    if(method === "month"){
      const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","De"];
      let result=[]
      for(let i=0;i<12;i++){
        result[i] = `${i+1} ${month[i]}`
      }
      
      return result;
    }
    return []
  }
  render() {
    const classes = useStyles;
    return (
      <Grid
        item
        md={7.9}
        xs={12}
        sx={{
          height: { lg: "615px", xs: "290px" },
          p: "10px",
          background: "#1B1B1B",
          borderRadius: "8px",
          ml: { md: "0px", xs: "0px" },
        }}
      >
        <Box
          id="chart"
          sx={{ flexDirection: "column", display: { md: "flex", xs: "none" } }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              m: "20px 0px",
            }}
          >
            <Box marginLeft="30px" display="flex">
              <Typography className={classes.graphtext}>
              {this.state.method==="month"?"Monthly":(this.state.method === "week"?"Weekly":"Yearly")} Revenue
              </Typography>
            </Box>
            <Box>
              <FormControl>
                <Select
                  value={this.state.method}
                  onChange={(e)=>this.state.setMethod(e.target.value)}
                  disableUnderline
                  variant="standard"
                  sx={{
                    height: "28px",
                    fontSize: "12px",
                    fontWight: 600,
                    borderRadius: "5px",
                    padding: "10px 5px",
                    color: "#BCBBB9",
                    width: "79px",
                    border: "1px solid #252525",
                    ".MuiSelect-icon": {
                      color: "white",
                    },
                  }}
                  
                >
                  <MenuItem value={"week"}>Week</MenuItem>
                  <MenuItem value={"month"}>Month</MenuItem>
                  <MenuItem value={"year"}>Year</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

      {
        !this.state.isFetching?(
          <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="line"
          height="515px"
        />
        ):(
          <Box sx={{width:"100%",minHeight:"350px",display:"flex",justifyContent:"center",paddingTop:"100px"}}>
            <CircularProgress  size={200}/>
          </Box>
        )
      }
        </Box>
        <Box
          id="chart"
          sx={{ flexDirection: "column", display: { md: "none", xs: "flex" } }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              m: "20px 0px",
            }}
          >
            <Box marginLeft="30px" display="flex">
              <Typography className={classes.graphtext}>
                {this.state.method==="month"?"Monthly":(this.state.method === "week"?"Weekly":"Yearly")} Revenue
              </Typography>
              <Select
                value={this.state.method}
                onChange={(e)=>this.state.setMethod(e.target.value)}
                variant="standard"
                sx={{
                  height: "28px",
                  fontSize: "12px",
                  fontWight: 600,
                  borderRadius: "5px",
                  color: "#BCBBB9",
                  margin: "-3px -15px",
                  ".MuiSelect-icon": {
                    color: "#BCBBB9",
                  },
                }}
              >
                 <MenuItem value={"week"}>Week</MenuItem>
                  <MenuItem value={"month"}>Month</MenuItem>
                  <MenuItem value={"year"}>Year</MenuItem>
              </Select>
            </Box>
            <Box>
              <FormControl>
                <Select
                  value="Staked"
                  disableUnderline
                  variant="standard"
                  sx={{
                    height: "28px",
                    fontSize: "12px",
                    fontWight: 600,
                    borderRadius: "5px",
                    padding: "10px 5px",
                    color: "#BCBBB9",
                    width: "79px",
                    border: "1px solid #252525",
                    ".MuiSelect-icon": {
                      color: "white",
                    },
                  }}
                >
                  <MenuItem value="Staked">Monthly</MenuItem>
                  <MenuItem value={10}>Tennn</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="line"
            height="200px"
          />
        </Box>
      </Grid>
    );
  }
}
export default ApexChart;
