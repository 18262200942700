import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "../../styles";

const Index = ({ name, date, earned, label, description }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} lg={12}>
      <Box
        sx={{
          height: "62px",
          background: { md: "#1B1B1B", xs: "#252525" },
          m: { md: "10px 0px", xs: "10px" },
          borderRadius: "8px",
          flexWrap: "wrap",
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          flexDirection: "row",
          padding: { md: "16px 25px", xs: "16px 20px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: { md: "14%", xs: "39%" },
          }}
        >
          <Typography className={classes.pointsname}>{name}</Typography>
        </Box>
        <Box mt="5px" display={{ md: "none", xs: "flex" }}>
          <Typography className={classes.date}>{date}</Typography>
        </Box>

        <Box display={{ md: "flex", xs: "none" }}>
          <Typography className={classes.pointsname}>{earned}</Typography>
        </Box>
        <Box mr="-10px" mt="-10px">
          <Typography color="#BCBBB9" className={classes.pointsname}>
            {label}
          </Typography>
          <Typography color="#717171" className={classes.pointsname}>
            {description}
          </Typography>
        </Box>

        <Box
          pr={{ md: "90px", xs: "20px" }}
          mt="5px"
          display={{ md: "flex", xs: "none" }}
        >
          <Typography className={classes.date}>{date}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Index;
