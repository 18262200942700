import * as React from "react";

import Typography from "@mui/material/Typography";
import {  date, p1, p2, p3 } from "../../imageSource";
import { Box } from "@mui/system";
import { useStyles } from "../../styles";
import { Button } from "..";
import { useLatestWithdrowHistory } from "../../hooks/withdrow";
import { getImg } from "../../pages/wallet/constant";
import CircularProgress from '@mui/material/CircularProgress';
const Index = () => {
  const {isFetching , data:latestHisData, isError} = useLatestWithdrowHistory();
  const changeDate = (date)=>{
    const _date = new Date(date);
    let year = _date.getFullYear();
    const month= _date.getMonth();
    const day = _date.getDay();
    year = year.toString().slice(2);
    return month+"/"+day+"/"+year;
  }
  const Data = React.useMemo(()=>{
    if(!isError && latestHisData?.status){
        return latestHisData?.data.map((item)=>{
          return {
            img:getImg(item.type),
            name:item.type,
            date:changeDate(item.updatedAt),
            rate:`$${item.amount}`
          }
        });
    }else return []
  },[latestHisData,isError])
  const classes = useStyles();
  return (
    <Box className={classes.Withdrow}>
      <Box className={classes.con1}>
        <Typography className={classes.lw}>Latest Withdrow</Typography>
        <Box
          display="flex"
          border="1px solid #252525"
          borderLeft="8px"
          p="8px"
        >
          <img src={date} alt=""/>
          <Typography ml="5px" mt="5px" className={classes.date}>
            25/01/2023
          </Typography>
        </Box>
      </Box>
      {
        !isFetching?(
          <>
            {Data.map((item) => {
              return (
                <Box className={classes.con2}>
                  <img src={item.img} alt=""/>
                  <Box width={"100px"}>
                    <Typography
                      color="white"
                      align="start"
                      className={classes.Withdrowlist}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="white" className={classes.Withdrowlist}>
                      {item.date}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="white" className={classes.Withdrowlist}>
                      {item.rate}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </>
        ):(
          <Box sx={{width:"100%",minHeight:"300px",display:"flex",justifyContent:"center",paddingTop:"50px"}}>
          <CircularProgress  size={50}/>
        </Box>
        )
      }
      <Box sx={{ display: "flex", mt: "15px", justifyContent: "end" }}>
        <Button
          label={"Show more"}
          textTransform="none"
          bg="#141414"
          height={"41px"}
          borderRadius="8px"
          width="133px"
        />
      </Box>
    </Box>
  );
};

export default Index;
