import { useQuery } from "@tanstack/react-query";
import { getTopAffilitedUser, ranking } from "../api/query-client";

export const useRanking = ()=>{
    const queryKey  = ['ranking'];
    return useQuery(queryKey,async ()=>{
        const {data} = await ranking();
        return data;
    })
}
export const useAffiliatedUsers = ()=>{
    const queryKey = ["getAffiliatedUsers"];
    return useQuery(queryKey,async ()=>{
        const {data} = await getTopAffilitedUser();
        return data;
    })
}