import Synaps from '@synaps-io/react-verify'
import { useWithdrow } from '../../context/withdrow_context'
export default function KYCComponent ({session_id}){
    const {setKycIsOpen,setWithdrowIsOpen} = useWithdrow();
    return(
        <Synaps
            sessionId={session_id}
            service={'individual'}
            lang={'en'}
            onReady={() => console.log('component ready')}
            onFinish={() =>{setWithdrowIsOpen(true);setKycIsOpen(false);}}
            color={{
            primary: '212b39',
            secondary: 'ffffff'
            }}
            withFinishButton={true}
        />
    )
}