export const host_url = process.env.REACT_APP_BASE_URL || "https://flash-transfer-master-backend.herokuapp.com";
export const base_url = host_url+"/api/affiliator";
console.log("base url:",base_url);
export const api_url = {
    getUser:"getUser",
    totalEarn:"/overview/getEarned",
    incomeProgress:"/overview/getIncomeProgress",
    reward:"/overview/getRewardPoint",
    totalPoint:"/statistics/getTotalPoint",
    rankingAffiliators:"/statistics/getRankingAffiliators",
    revene:"/statistics/getRevenue",
    ranking:"/leaderboard/getRanking",
    withdrowCrypto:"/withdrow/crypto",
    withdrowPaypal:"/withdrow/paypal",
    withdrowBank:"/withdrow/bank",
    transactions:"/transactions",
    commisions:"/commission",
    updateProfileImg:"/updateProfileImg",
    updateProfile:"/updateProfile",
    createAffiliator:"/createAffiliator",
    checkKYCVerification:"/kyc/check",
    // verifyOTP:"/withdrow/verifyOTPForWithdrow",
    verifySMS :"/withdrow/smsVerify",
    resendSMS:"/withdrow/resendSmsCode",
    getWithdrowHis:"/withdrow/getHistory",
    getLatestWidHistory:"/withdrow/getLatestWidHistory",
    getTopAffilitedUser:"/leaderboard/topAffilited",
    balance:"/withdrow/getBalance",
    topRevene:"/statistics/topRevenue"
}