import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Button } from "..";
import { need } from "../../imageSource";
import { useStyles } from "../../styles";
const Index = () => {
  const classes = useStyles();

  return (
    <Grid>
      <Box
        sx={{
          mt: "15px",
          background: "#1B1B1B",
          borderRadius: "8px",
          padding: "15px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          <img src={need} alt=""/>
        </Box>
        <Typography mt="24px" className={classes.still}>
          Do you still need our help?
        </Typography>

        <Typography className={classes.typeof}>
          Send your request via email.
        </Typography>
        <Box>
          <Grid
            item
            md={12}
            sx={{
              mt: "20px",
              mb:"10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box width={{ md: "354.29px", xs: "300.96px" }}>
              <Button
                // width="354.29px"
                textTransform="capitalize"
                label="Contact us"
                border="none"
                height="57.44px"
                bg="#FBBF04"
                color={"black"}
              />
            </Box>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default Index;
