import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "../../styles";
import CircularProgress from '@mui/material/CircularProgress';
const Index = ({ label, value, img, img1 ,isFetching}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={3.06} xl={3.05} ml={{ md: "-5px", xs: "0px" }}>
      <Box
        m={{ md: "10px 10px", xs: "10px 0px" }}
        className={classes.amountcard2}
        sx={{
          height: "128px",
          flexDirection: "column",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          background:
            "linear-gradient(180deg, #1B1B1B 0%, rgba(38, 38, 38, 0.95) 48.44%, #1B1B1B 100%)",
        }}
      >
        {
          !isFetching?(
            <>
                {img1 ? (
                  <Box mb="-65px" mt="-20px" position="relative">
                    <Box sx={{}}>
                      <img src={img1} alt="" />
                    </Box>
                    <Box>
                      <img
                        src={img}
                        style={{
                          marginTop: "-90px",
                          position: "relative",
                        }}
                        alt=""
                      />{" "}
                    </Box>
                  </Box>
                ) : null}
                <Box
                  mt="0px"
                  position="relative"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Typography className={classes.amount1}>{label}</Typography>
                  <Typography className={classes.aDuration}>{value}</Typography>
                </Box>
              </>
           ):(
            <CircularProgress  size={50}/>
           )
        }
      </Box>
    </Grid>
  );
};

export default Index;
