import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify";
import { useLocalStorateState } from ".";
import { getProfile, updateProfileImg ,updateProfile, queryClient, createAffiliator} from "../api/query-client"
import {memo} from "react";
export const useProfile = ()=>{
    return  useQuery(["getUser"],async ()=>{
        const {data} = await getProfile();
        return data;
    },{
        staleTime:3000,
    });
};
export const useUpdateProfileImg = ()=>{
    const queryClient = useQueryClient()
    return useMutation((img)=>updateProfileImg({profileImg:img}),{
        onError:(error,value,context)=>{
            toast.error(error?.message);
        },
        onSuccess:(data,value,context)=>{
            queryClient.invalidateQueries(["getUser"]);
        }
    });
}
export const useUpdateProfile = ()=>{
    const queryClient = useQueryClient()
    return useMutation(({firstName,lastName,userName,phone,email,confirmEmail})=>updateProfile({firstName,lastName,userName,phone,email,confirmEmail}),{
        onError:(error,val,context)=>{
            if(error?.response?.data?.error){
                toast.error(error?.response?.data?.error);
            }else{
                toast.error(error?.message);
            }
            
        },
        onSuccess:(data,value,context)=>{
           if(data?.data?.status){
            toast.success("updated is successful!");
           }else{
            toast.error(data?.data?.msg)
           }
            
            queryClient.invalidateQueries(['getUser']);
        }
    });
}
export const useSignUpOnBack = ()=>{
    const queryClient = useQueryClient()
    return useMutation(({firstName,lastName,email,password})=>{
        return createAffiliator({firstName,lastName,email,password});
    },{
        onError:(error,value,context)=>{
            if(error?.response?.data?.error) return toast.error(error?.response?.data?.error);
            else return toast.error(error?.message)
        },
        onSuccess:(data,value,context)=>{
            console.log("data",data);
            if(data?.data?.status){
                toast.success("Please verify your Email");
                queryClient.invalidateQueries(["getUser"]);
            }else{
                toast.error(data?.data?.msg);
            }
        }
    })
}