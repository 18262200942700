import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, Modal2, Modal7, Modal8 } from "..";
import { close } from "../../imageSource";
import { useStyles } from "../../styles";
import { useCheckKYCVerification } from "../../hooks/withdrow";
import { toast } from "react-toastify";
import KYCComponent from "../kyc";
import { useWithdrow } from "../../context/withdrow_context";

export default function BasicModal() {
  const {
    kycIsOpen,
    setKycIsOpen,
    withdrowIsOpen,
    setWithdrowIsOpen,
    setPayWay,
  } = useWithdrow();

  const { isFetching, data, isError, error } = useCheckKYCVerification();
  if (isError || !data?.status) {
    toast.error(isError ? error?.message : data?.msg);
  }
  const CheckKYC = React.useMemo(() => {
    if (data?.status) {
      return { sessionId: data?.session_id, verified: data?.verified };
    } else return null;
  }, [isError, data]);
  const handleOpen = React.useCallback(() => {
    if (CheckKYC && CheckKYC?.verified && CheckKYC?.verified === "VERIFIED") {
      setWithdrowIsOpen(true);
    } else {
      setKycIsOpen(true);
    }
  }, [CheckKYC]);
  const handleClose = React.useCallback(() => {
    if (CheckKYC && CheckKYC?.verified && CheckKYC?.verified === "VERIFIED") {
      setWithdrowIsOpen(false);
    } else {
      setKycIsOpen(false);
    }
  }, [CheckKYC]);
  const classes = useStyles();
  return (
    <div>
      <Box width="311px">
        <Button
          isLoading={isFetching}
          label={"Withdrow"}
          textTransform="none"
          bg="#141414"
          borderRadius="8px"
          onClick={handleOpen}
        />
      </Box>

      <Modal
        open={withdrowIsOpen}
        onClose={() => setWithdrowIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            p: "25px",
            transform: "translate(-50%, -50%)",
            background: "#1B1B1B",
            borderRadius: "8px",
            flexDirection: "column",
            display: "flex",
            width: { md: "492px", xs: "335px" },
            height: { md: "390px", xs: "385px" },
          }}
        >
          <Box
            pb="10px"
            borderBottom="1px solid #252525"
            display="flex"
            justifyContent="space-between"
          >
            <Typography className={classes.transactiontext}>
              Withdraw Money
            </Typography>
            <img src={close} alt="close" onClick={handleClose} />
          </Box>
          <Typography mt="32px" className={classes.modal1text} sx={{ mt: 2 }}>
            Add Payment Method
          </Typography>
          <Typography color="#717171" className={classes.Withdrowlist}>
            Add your payment method in few click.
          </Typography>
          <Box display="flex">
            <Box onClick={() => setPayWay(1)}>
              <Modal2 />
            </Box>
            <Box onClick={() => setPayWay(2)}>
              <Modal8 />
            </Box>
            <Box onClick={() => setPayWay(0)}>
              <Modal7 />
            </Box>
          </Box>
          <Box mt="32px">
            <Box display={{ md: "flex", xs: "none" }}>
              <Button
                width="442px"
                textTransform="capitalize"
                label="Continue"
                border="none"
                height="41px"
                bg="#FBBF04"
                color={"#151103"}
              />
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={kycIsOpen}
        onClose={() => setKycIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            p: "25px",
            transform: "translate(-50%, -50%)",
            background: "#1B1B1B",
            borderRadius: "8px",
            flexDirection: "column",
            display: "flex",
            width: { md: "492px", xs: "335px" },
            minHeight: { md: "390px", xs: "385px" },
          }}
        >
          <Box
            pb="10px"
            borderBottom="1px solid #252525"
            display="flex"
            justifyContent="space-between"
          >
            <Typography className={classes.transactiontext}>
              KYC Verification
            </Typography>
            <img src={close} alt="close" onClick={handleClose} />
          </Box>
          {CheckKYC && CheckKYC?.sessionId && (
            <KYCComponent session_id={CheckKYC?.sessionId} />
          )}
        </Box>
      </Modal>
    </div>
  );
}
