import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import  { useState } from "react";
import { AppInput, Button } from "..";
import logo from "../../assets/images/loginlogo.png";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box } from "@mui/system";
import { useStyles } from "../../styles";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth_context";
const Index = () => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const {signInEmail} = useAuth();
  const {isLoading ,isError,isSuccess} = signInEmail;
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const handleLogin = ()=>{
      signInEmail.mutate({email:email,password:password})
  }
  return (
    <Grid
      container
      sx={{
        pt: "30px",
        height: { md: "103vh", xs: "105vh" },
        pb: { md: "0px", xs: "160px" },
        overflow: { md: "none", xs: "scroll !important" },
        background: { md: "#1B1B1B", xs: "black" },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        sx={{
          color: "white",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            mb: "113.08px",
            justifyContent: "center",
          }}
        >
          <Box display={{ md: "flex", xs: "none" }} mr="11.98px">
            <img src={logo} alt="" />
          </Box>
          <Box display={{ md: "none", xs: "flex" }} mr="11.98px">
            <img src={logo} alt="" width="42.5px" height="39.52px" />
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: { md: "29.273px", xs: "19.31px" },
              lineHeight: "53px",
            }}
          >
            Flash Affiliate
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "19px",
            }}
          >
            Email Address
          </Typography>
          <Box width={{ md: "563.29px", xs: "335px" }}>
            <AppInput
              margin={"12.62px 0px 0px 0px"}
              value={email}
              onChange ={(e)=>setEmail(e.target.value)}
              // width="563.29px"
              borderRadius={"10px"}
              height="57.44px"
              color="white"
              placeholder="example@gmail.com"
              backgroundColor="#161616"
            />
          </Box>
        </Grid>
        <Grid item md={12} xs={12} mt="22px">
          <Typography className={classes.titless}>Password</Typography>

          <Box width={{ md: "563.29px", xs: "335px" }}>
            <TextField
              sx={{
                padding: "0px 10px 0px 20px",
                margin: "12.62px 0px 0px 0px",
                borderRadius: "10px",
                height: "57.44px",
                background: "#161616",
                "& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root": {
                  color: "white",
                },
                " & .MuiOutlinedInput-notchedOutline": {
                  border: " none !important",
                },
                "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                  color: "#848484",
                },
                "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:hover":
                  {
                    color: "#717171",
                  },
                "& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
                  // padding: "16.5px 20px !important",
                },

                input: {
                  "&::placeholder": {
                    textOverflow: "ellipsis !important",
                    color: "white",
                  },
                },
              }}
              placeholder="Password"
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="phoneNumber"
              disableUnderline={false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityOff
                          style={{
                            fill: "gray",
                          }}
                        />
                      ) : (
                        <Visibility
                          style={{
                            fill: "gray",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <AppInput
              type="password"
              margin={"12.62px 0px 0px 0px"}
              // width="563.29px"
              borderRadius={"10px"}
              height="57.44px"
              color="white"
              placeholder="Password"
              backgroundColor="#161616"
              position="end"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">To</InputAdornment>
                ),
              }}
            /> */}
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            mt: { md: "28.72px", xs: "8px" },
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box display="flex" alignItems="center">
            <Checkbox
              //   size="large"
              // sx={{

              // }}
              sx={{
                transform: { md: "scalex(1.5)", xs: "scale(none)" },
                color: "#BCBBB9",
                "&.Mui-checked": {
                  border: "none",
                  color: "#BCBBB9",
                },
              }}
            />
            <Typography
              sx={{
                color: "#BCBBB9",
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "19px",
              }}
            >
              Remember me
            </Typography>
          </Box>
          <Link to="/forgotpassword" style={{textDecoration:"none"}}>
          <Typography
            sx={{
              paddingRight: { md: "30px", xs: "10px" },
              mt: "10.98px",
              fontFamily: "Inter",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#FCC005",
            }}
            >
            Reset Password?
          </Typography>
            </Link>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            mt: { md: "30px", xs: "103px" },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          
            <Box width={{ md: "354.29px", xs: "335px" }}>
              <Button
                // width="354.29px"
                isLoading={isLoading}
                textTransform="capitalize"
                label="Login"
                border="none"
                height="57.44px"
                bg="#FBBF04"
                color={"black"}
                onClick={handleLogin}
              />
            </Box>
          
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            mt: { md: "40px", xs: "16px " },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: "#BCBBB9",
              fontFamily: "Inter",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "19px",
            }}
          >
            Don’t have account yet?
          </Typography>
          <Link to="/Signup" style={{ textDecoration: "none" }}>
            <Typography
              sx={{
                paddingLeft: "10px",
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#FCC005",
              }}
            >
              New Account
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Index;
