import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button } from "..";
import {
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  bell,
  mark,
} from "../../imageSource";
import { useStyles } from "../../styles";
import { Backdrop, IconButton } from "@mui/material";

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  return (
    <div>
      <Box display={{ md: "flex", xs: "none" }}>
        <IconButton
          onClick={handleOpen}
          sx={{
            height: "44px",
            backgroundColor: "#252525",
            borderRadius: "36px",
            width: "44px",
          }}
        >
          <img src={bell} alt="bell" />
        </IconButton>
      </Box>
      <Box display={{ md: "none", xs: "flex" }}>
        <IconButton
          onClick={handleOpen}
          sx={{
            height: "24px",
            backgroundColor: "#252525",
            borderRadius: "36px",
            width: "24px",
          }}
        >
          <img src={bell} width="14px" height="14px" alt="bell" />
        </IconButton>
      </Box>

      <Modal
        open={open}
        onClick={handleClose}
        BackdropComponent={Backdrop}
        // hideBackdrop={true}
        // closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: { md: "50%", xs: "46%" },
            left: { md: "72%", xs: "50%" },
            p: { md: "25px", xs: "12px" },
            transform: "translate(-50%, -50%)",
            background: "#1B1B1B",
            borderRadius: "8px",
            flexDirection: "column",
            display: "flex",
            width: { md: "372px", xs: "335px" },
            height: { md: "511px", xs: "423px" },
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.lw}>Notification</Typography>
            <Box display="flex" alignItems="center">
              <Box m="5px">
                <img src={mark} alt=""/>
              </Box>

              <Typography className={classes.Withdrowlist}>
                Mark all as read
              </Typography>
            </Box>
          </Box>
          <Box
            pt={{ md: "26px", xs: "10px" }}
            pb="10px"
            display="flex"
            justifyContent="space-between"
            alignItems="end"
            borderBottom="1px solid #252525"
          >
            <Box mr={{ md: "-30px", xs: "-20px" }}>
              <img src={avatar1} alt=""/>
            </Box>
            <Box>
              <Typography className={classes.Withdrowlist} component="h2">
                Paid $124.76 for invoice{" "}
                <span style={{ color: "#FBBF04" }}> #169841 </span>
              </Typography>
              <Typography className={classes.Withdrowlist} color="#BCBBB9">
                Gladys Dare
              </Typography>
            </Box>
            <Typography className={classes.headeruserdes} color="#BCBBB9">
              11:40 AM
            </Typography>
          </Box>
          <Box
            pt="10px"
            pb="10px"
            display="flex"
            justifyContent="space-between"
            alignItems="end"
            borderBottom="1px solid #252525"
          >
            <Box mr={{ md: "-60px", xs: "-50px" }}>
              <img src={avatar2} alt=""/>
            </Box>
            <Box>
              <Typography className={classes.Withdrowlist} component="h2">
                Withdraw sent for
                <span style={{ color: "#FBBF04" }}> #169882 </span>
              </Typography>
              <Typography className={classes.Withdrowlist} color="#BCBBB9">
                Dorothy Parker
              </Typography>
            </Box>
            <Typography className={classes.headeruserdes} color="#BCBBB9">
              11:40 AM
            </Typography>
          </Box>
          <Box mt="13px">
            <Typography color="#BCBBB9" className={classes.Withdrowlist}>
              Yesterday
            </Typography>
          </Box>
          <Box
            pt="10px"
            pb="10px"
            display="flex"
            justifyContent="space-between"
            alignItems="end"
            borderBottom="1px solid #252525"
          >
            <Box mr={{ md: "0px", xs: "10px" }}>
              <img src={avatar3} alt=""/>
            </Box>
            <Box>
              <Typography className={classes.Withdrowlist} component="h2">
                Created the new client
                <span style={{ color: "#FBBF04" }}> Binford Limited </span>
              </Typography>
              <Typography className={classes.Withdrowlist} color="#BCBBB9">
                Rodolfo Goode
              </Typography>
            </Box>
            <Typography className={classes.headeruserdes} color="#BCBBB9">
              11:40 AM
            </Typography>
          </Box>
          <Box
            pt="10px"
            pb="10px"
            display={{ md: "flex", xs: "none" }}
            justifyContent="space-between"
            alignItems="end"
            borderBottom="1px solid #252525"
          >
            <Box mr={{ md: "0px", xs: "10px" }}>
              <img src={avatar3} alt="" />
            </Box>
            <Box>
              <Typography className={classes.Withdrowlist} component="h2">
                Created the new client
                <span style={{ color: "#FBBF04" }}> Eric Lorlatomo </span>
              </Typography>
              <Typography className={classes.Withdrowlist} color="#BCBBB9">
                Rodolfo Goode
              </Typography>
            </Box>
            <Typography className={classes.headeruserdes} color="#BCBBB9">
              11:40 AM
            </Typography>
          </Box>{" "}
          <Box p="13px 0px">
            <Typography color="#BCBBB9" className={classes.Withdrowlist}>
              March 23, 2022
            </Typography>
          </Box>
          <Box
            pb="10px"
            display="flex"
            justifyContent="space-between"
            alignItems="end"
            borderBottom="1px solid #252525"
          >
            <Box mr={{ md: "-60px", xs: "-50px" }}>
              <img src={avatar4} alt=""/>
            </Box>
            <Box>
              <Typography className={classes.Withdrowlist} component="h2">
                Withdraw sent for
                <span style={{ color: "#FBBF04" }}> #169582 </span>
              </Typography>
              <Typography className={classes.Withdrowlist} color="#BCBBB9">
                Peter Parker
              </Typography>
            </Box>
            <Typography className={classes.headeruserdes} color="#BCBBB9">
              11:40 AM
            </Typography>
          </Box>
          <Box
            mt={{ md: "25px", xs: "10px" }}
            width={{ md: "322px", xs: "303px" }}
          >
            <Button
              textTransform="capitalize"
              label="View all notification"
              height="41px"
              color={"#FBBF04"}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
