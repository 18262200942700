import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  Layout,
  Balance,
  LatestWithdrow,
  Transaction,
  Report,
} from "../../components";
import { useStyles } from "../../styles";
import { Box } from "@mui/system";

const Index = () => {
  const classes = useStyles();
  return (
    <Layout name="headername">
      <Grid>
        <Typography className={classes.maintext}>Wallet</Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: { md: "67px", xs: "16px" }, mb: "220px" }}
      >
        <Grid item md={4} xl={3} >
          <Balance />
          <Box mt={{ md: "24px", xs: "30px" }}>
            <LatestWithdrow />
          </Box>
          <Box mt={{ md: "24px", xs: "35px" }}>
            <Report />
          </Box>
        </Grid>
        <Grid item md={8} xs={12} xl={9}>
          <Transaction />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Index;
