import { p1, p2, p3 } from "../../imageSource";
export const WithdrowStatus =[
    "Pending",
    "Completed"
]
export const WithdorwBorder = [
    "1px solid #F86E51",
    "1px solid #55BA68"
]
export const WithdrowImg={
    paypal:p1,
    bank:p2,
    busd:p3
}
export const WithdrowColor = [
    "#F86E51",
    "#55BA68"
]
export function getImg(type){
    switch(type){
        case "PAYPAL": return WithdrowImg.paypal;
        case "BANK TRANSFER": return WithdrowImg.bank;
        case "BUSD": return WithdrowImg.busd;
    }
}
export function getBorder  (status){
    return WithdorwBorder[status];
}
export function getStatus (status){
    return WithdrowStatus[status]
}
export function getWithdrowColor(status){
    return WithdrowColor[status];
}
