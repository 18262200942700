import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useStyles } from "../../styles";
import { Button } from "..";
import { FormControl, MenuItem, Select } from "@mui/material";

const Index = () => {
  const classes = useStyles();
  return (
    <Box className={classes.WithdrowR}>
      <Box>
        <Typography className={classes.lw}>Reports</Typography>
      </Box>
      <Box mt="17px">
        <Typography className={classes.rep}>Transaction Type</Typography>
        <FormControl>
          <Select
            value="Staked"
            disableUnderline
            variant="standard"
            sx={{
              mt: "10px",
              height: "42px",
              fontSize: "12px",
              fontWight: 600,
              borderRadius: "8px",
              padding: "12px 16px",
              color: "#717171",
              width: "311px",
              background: "#252525",
              ".MuiSelect-icon": {
                color: "#717171",
              },
            }}
          >
            <MenuItem value="Staked">All Transaction</MenuItem>
            <MenuItem value={10}>Tennn</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box mt="10px">
        <Typography className={classes.rep}>Select the problem</Typography>
        <FormControl>
          <Select
            value="Staked"
            disableUnderline
            variant="standard"
            sx={{
              mt: "10px",
              height: "42px",
              fontSize: "12px",
              fontWight: 600,
              borderRadius: "8px",
              padding: "12px 16px",
              color: "#717171",
              width: "311px",
              background: "#252525",
              ".MuiSelect-icon": {
                color: "#717171",
              },
            }}
          >
            <MenuItem value="Staked">Not received</MenuItem>
            <MenuItem value={10}>Tennn</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          mr: { md: "", xs: "10px" },
          display: "flex",
          mt: "15px",
          justifyContent: "end",
        }}
      >
        <Button
          label={"Send"}
          textTransform="none"
          bg="#FBBF04"
          height={"41px"}
          color="black"
          borderRadius="8px"
          width="133px"
        />
      </Box>
    </Box>
  );
};

export default Index;
