import { Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { Layout, PeopleCard } from "../../components";
import people from "../../assets/images/peopleimg.svg";
import { useStyles } from "../../styles";
import points from "../../assets/images/points.svg";
import { Box } from "@mui/system";
import { useLastTransaction } from "../../hooks/overview";
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';
const SetCard = [
  {
    img2: points,
    img1: people,
    name: "Bessie",
    label: "Flash-transfer.com",
    desc: "Website used",
    points: "75",
    date: "Feb 6, 2021 3:18 am",
    earned: "$0,75",
  },
  {
    img2: points,
    img1: people,
    name: "Bessie",
    label: "Flash-transfer.com",
    desc: "Website used",
    points: "75",
    date: "Feb 6, 2021 3:18 am",
    earned: "$0,75",
  },
  {
    img2: points,
    img1: people,
    name: "Bessie",
    label: "Flash-transfer.com",
    desc: "Website used",
    points: "75",
    date: "Feb 6, 2021 3:18 am",
    earned: "$0,75",
  },
  {
    img2: points,
    img1: people,
    name: "Bessie",
    label: "Flash-transfer.com",
    desc: "Website used",
    points: "75",
    date: "Feb 6, 2021 3:18 am",
    earned: "$0,75",
  },
  {
    img2: points,
    img1: people,
    name: "Bessie",
    label: "Flash-transfer.com",
    desc: "Website used",
    points: "75",
    date: "Feb 6, 2021 3:18 am",
    earned: "$0,75",
  },
  {
    img2: points,
    img1: people,
    name: "Bessie",
    label: "Flash-transfer.com",
    desc: "Website used",
    points: "75",
    date: "Feb 6, 2021 3:18 am",
    earned: "$0,75",
  },
  {
    img2: points,
    img1: people,
    name: "Bessie",
    label: "Flash-transfer.com",
    desc: "Website used",
    points: "75",
    date: "Feb 6, 2021 3:18 am",
    earned: "$0,75",
  },
  {
    img2: points,
    img1: people,
    name: "Bessie",
    label: "Flash-transfer.com",
    desc: "Website used",
    points: "75",
    date: "Feb 6, 2021 3:18 am",
    earned: "$0,75",
  },
  {
    img2: points,
    img1: people,
    name: "Bessie",
    label: "Flash-transfer.com",
    desc: "Website used",
    points: "75",
    date: "Feb 6, 2021 3:18 am",
    earned: "$0,75",
  },
  {
    img2: points,
    img1: people,
    name: "Bessie",
    label: "Flash-transfer.com",
    desc: "Website used",
    points: "75",
    date: "Feb 6, 2021 3:18 am",
    earned: "$778.35",
  },
];
const Index = () => {
  const classes = useStyles();
  const {isFetching:trIsFetching,data:trData,isError:trIsError,error:trError} = useLastTransaction();
  if(trIsError || !trData?.status){
    toast.error(trIsError?trError?.message:trData?.msg);
  }
  const TrData = useMemo(()=>{
    if(trData?.status && !trIsError){
       return trData.lstTransactions;
    }else return [];
  },[trData,trIsError]) ;
  const transactions = TrData.map((item)=>{

    return {
      img2:points,
      img1:item.profileImg,
      name: "Username",
      namdec:item.firstName+" "+item.lastName,
      desc:item.createdAt,
      points:item.amount*100,
      earned: "Earned",
      date:"$"+item.amount
    }
  })
  return (
    <Layout>
      <Grid container spacing={2} sx={{ mb: "190px" }}>
        <Grid item xs={12} lg={12}>
          <Grid>
            <Typography className={classes.maintext}>People</Typography>
          </Grid>
          <Box
            sx={{
              mt: { md: "25px", xs: "-4px" },
              height: "50px",
              borderBottom: "1px solid  #201D16",
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              padding: {
                md: "16px 100px 16px 30px",
                xs: "16px 0px 16px 0px",
              },
            }}
          >
            <Box>
              <Typography color="#717171" className={classes.theader}>
                Username
              </Typography>
            </Box>
            <Box>
              <Typography
                color="#717171"
                mr={{ md: "-60px", xs: "40px" }}
                pl={{ md: "15px", xs: "50px" ,xl:"50px"}}
                
                className={classes.theader}
              >
                Earned
              </Typography>
            </Box>{" "}
            <Box>
              <Typography
                color="#717171"
                mr={{ md: "0px", xs: "50px" }}
                className={classes.theader}
              >
                About
              </Typography>
            </Box>{" "}
            <Box display={{ md: "flex", xs: "none" }}>
              <Typography color="#717171" className={classes.date}>
                Points earned
              </Typography>
            </Box>{" "}
            <Box display={{ md: "flex", xs: "none" }}>
              <Typography color="#717171" className={classes.date}>
                Date
              </Typography>
            </Box>
          </Box>
        </Grid>
       {
        !trIsFetching?(
          <>
            {transactions.map((data) => {
                return (
                    <PeopleCard
                      name={data.name}
                      date={data.date}
                      points={data.points}
                      earned={data.earned}
                      img1={data.img1}
                      label={data.label}
                      description={data.desc}
                      pointsimg={data.img2}
                    />
                );
              })}
          </>
        ):(
          <Box sx={{width:"100%",minHeight:"300px",display:"flex",justifyContent:"center",paddingTop:"50px"}}>
            <CircularProgress  size={100}/>
          </Box>
        )
       }
      </Grid>
    </Layout>
  );
};

export default Index;
