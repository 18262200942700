import { Grid, Box, Typography } from "@mui/material";
// import Switch, { SwitchProps } from "@mui/material/Switch";
import React from "react";
import { cal } from "../../imageSource";
import { useStyles } from "../../styles";

const LastTranaction = ({
  name,
  pointsimg,
  date,
  earned,
  img1,
  namec,
  points,
  label,
  description,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} lg={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          padding: "16px 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box mr="10px">
            <img src={img1} width={30} height={30} alt="" style={{borderRadius:"100px"}}/>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <Typography
              color="#BCBBB9"
              mt="-7px"
              className={classes.headeruser}
            >
              {name}
            </Typography>
            <Typography className={classes.modal1text}>{namec}</Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box mr="10px">
            <img src={cal} alt="" />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <Typography
              color="#BCBBB9"
              // mt="-7px"
              className={classes.headeruser}
            >
              {label}
            </Typography>
            <Typography className={classes.modal1text}>
              {description}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { md: "flex", xs: "none" },
          }}
        >
          <Box mr="10px">
            <img src={pointsimg} alt="" />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <Typography color="#BCBBB9" className={classes.headeruser}>
              Points Earned
            </Typography>
            <Typography className={classes.modal1text}>{points.toFixed(0)}</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: { md: "flex", xs: "none" },
            flexDirection: "column",
          }}
        >
          <Typography color="#BCBBB9" className={classes.headeruser}>
            {earned}
          </Typography>

          <Typography className={classes.modal1text}>{date}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default LastTranaction;
