import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { AppInput, Button, Modal3, Modal4 } from "..";
import { bank, close, crypto, paypal, userimg } from "../../imageSource";
import { useStyles } from "../../styles";
import { Link } from "react-router-dom";
import { useWithdrow } from "../../context/withdrow_context";

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {crypto_address , amount,setAmount,setCryptoAddress} = useWithdrow()
  const classes = useStyles();
  return (
    <div>
      <Box className={classes.modalcard} onClick={handleOpen}>
        <Box>
          <img src={crypto} alt="" />
        </Box>
        <Box p={{ md: "0px", xs: "5px" }} width={{ md: "128px", xs: "93px" }}>
          <Typography
            mt={{ md: "16px", xs: "2px" }}
            className={classes.modal1text}
          >
            Crypto payment
          </Typography>
          <Typography color="#BCBBB9" mt="4px" className={classes.modal2text}>
            Transfer crypto directly to your wallet
          </Typography>
        </Box>
      </Box>{" "}
      {/* <Button
        textTransform="capitalize"
        fontSize={"12px"}
        label="remove"
        height="41px"
        border="none"
        bg="#1B1B1B"
        color="#F86E51"
        onClick={handleOpen}
      /> */}
      <Modal
        open={open}
        onClose={handleClose}
        hideBackdrop={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            p: "25px",
            transform: "translate(-50%, -50%)",
            background: "#1B1B1B",
            borderRadius: "8px",
            flexDirection: "column",
            display: "flex",
            // width: "492px",
            width: { md: "492px", xs: "335px" },
            height: "455px",
          }}
        >
          <Box
            pb="10px"
            borderBottom="1px solid #252525"
            display="flex"
            justifyContent="space-between"
          >
            <Typography className={classes.lw} variant="h6" component="h2">
              Withdraw Money
            </Typography>
            <img src={close} alt="close" onClick={handleClose} />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="12px"
          ></Box>

          <Box display="flex" justifyContent="space-between">
            <Box mt="13px">
              <Typography className={classes.titless}>
                EVM Crypto Address
              </Typography>
              <Box width={{ md: "443px", xs: "301.64px" }}>
                <AppInput
                  value={crypto_address}
                  onChange={(e)=>setCryptoAddress(e.target.value)}
                  type="City bank"
                  margin={"12.62px 0px 0px 0px"}
                  // width="443px"
                  borderRadius={"10px"}
                  height="50px"
                  color="#F86E51"
                  placeholder="Enter your crypto address.."
                  backgroundColor="#161616"
                  position="end"
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box mt="13px">
              <Typography className={classes.titless}>Amount</Typography>
              <Box width={{ md: "443px", xs: "301.64px" }}>
                <AppInput
                  value = {amount}
                  onChange = {(e)=>setAmount(e.target.value)}
                  type=""
                  margin={"12.62px 0px 0px 0px"}
                  // width="443px"
                  borderRadius={"10px"}
                  height="50px"
                  color="white"
                  placeholder="500 BUSD"
                  backgroundColor="#161616"
                  position="end"
                />
              </Box>
            </Box>
          </Box>
          <Box mt="24px" display="flex" justifyContent="center">
            <Typography
              color="#FBBF04BA"
              align="center"
              width="400px"
              className={classes.accordtext}
            >
              If you are using a crypto address from an exchange, make sure it
              supports the BNB Chain network and BUSD !
            </Typography>
          </Box>
          <Box width={{ md: "442px", xs: "300.96px" }} mt="24px">
            <Modal4 name />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
