import {  Grid } from "@mui/material";
import React from "react";
import { SignUpForm } from "../../components";

const Index = () => {
  return (
    
      <Grid container>
        <SignUpForm />
      </Grid>
  
  );
};

export default Index;
